import { CheckFillIcon, ChevronUpIcon, CloseCircleIcon, ErrorCircleIcon, LoaderIcon } from 'assets/svg';
import cn from 'classnames';
import { Button, Typography } from 'components';
import { OfflineTicketEntryStatus } from 'enums';
import { formatDateTime } from 'helpers';
import { useVerify } from 'pages/CheckIn/hooks/useVerify';
import { useState } from 'react';
import { useGetTicketByIdQuery } from 'store';
import InvalidTicket from '../InvalidTicket/InvalidTicket';
import MoreOptions from '../MoreOptions/MoreOptions';
import styles from './TicketInfo.module.scss';

interface TicketInfoProps {
  ticketId: string;
  eventId: string;
  onClose: () => void;
}

const TicketInfo = ({ ticketId, eventId, onClose }: TicketInfoProps) => {
  const [showMore, setShowMore] = useState(false);
  const { data, isLoading, isError } = useGetTicketByIdQuery({ id: ticketId });
  const { onConfirm, onDeny, onBlock, onUnBlock } = useVerify(ticketId, onClose);

  const isInvalidEvent = data?.eventId !== eventId;

  const toggleMoreOptions = () => {
    setShowMore(!showMore);
  };

  if (showMore) {
    return <MoreOptions onDeny={onDeny} onBlock={onBlock} onClose={toggleMoreOptions} />;
  }

  if (isError || isInvalidEvent) {
    return <InvalidTicket isInvalidTicket={isError} onClose={onClose} />;
  }

  if (!data || isLoading) {
    return <LoaderIcon />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <CheckFillIcon />
          <Typography variant="bodyXl">Valid Ticket!</Typography>
        </div>
        <CloseCircleIcon className={styles.headerCloseIcon} onClick={onClose} />
      </div>

      <div>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrapperInfo, styles.wrapperInfoWarning)}>
            <ErrorCircleIcon />
            <Typography variant="bodyXs">Please check guest ID!</Typography>
          </div>
          <div className={styles.wrapperContent}>
            <Typography variant="bodyL" weight="medium">
              {data.attendeeName}
            </Typography>
            <Typography variant="bodyS" color="gray100">
              {data.attendeeEmail}
            </Typography>
          </div>
        </div>

        <div className={cn(styles.wrapper, 'p-12')}>
          <Typography variant="bodyS" color="gray300">
            Notes:
          </Typography>
          <Typography variant="bodyMd" className="m-b-12">
            {data.attendeeNotes?.notes}
          </Typography>
          {data.attendeeNotes?.notes && (
            <div className={styles.wrapperInfo}>
              <ErrorCircleIcon />
              <Typography variant="bodyXs">Make sure to verify all guests IDs</Typography>
            </div>
          )}
        </div>

        <div className={cn(styles.wrapper, 'p-12')}>
          <Typography variant="bodyS" color="gray300">
            Entry Log:
          </Typography>
          <div className="m-b-12">
            {data.entryNotes?.map((entryNote) => (
              <div key={entryNote.date} className={styles.wrapperEntryLog}>
                <Typography variant="bodyS">{entryNote.text}</Typography>
                <Typography variant="bodyS">{formatDateTime(entryNote.date)}</Typography>
              </div>
            ))}
          </div>
          {data.entryNotes?.length && data.entryNotes.length > 3 && (
            <div className={cn(styles.wrapperInfo, styles.wrapperInfoDanger)}>
              <ErrorCircleIcon />
              <Typography variant="bodyXs">Suspicious Activity</Typography>
            </div>
          )}

          {data.entryStatus === OfflineTicketEntryStatus.Blocked && (
            <Button variant="primary" width="100%" type="button" size="S" className="m-t-12" onClick={onUnBlock}>
              Unblock Guest
            </Button>
          )}
        </div>
      </div>

      {(data.entryStatus === OfflineTicketEntryStatus.Pending ||
        data.entryStatus === OfflineTicketEntryStatus.Denied) && (
        <div>
          <Button variant="primary" width="100%" type="button" size="M" className="m-b-12" onClick={onConfirm}>
            Check In
          </Button>

          <Button
            variant="tertiary"
            width="100%"
            type="button"
            size="M"
            iconRight={<ChevronUpIcon />}
            onClick={toggleMoreOptions}>
            More Options
          </Button>
        </div>
      )}
    </div>
  );
};

export default TicketInfo;
