import { CloseCircleIcon, ErrorIcon } from 'assets/svg';
import { Typography } from 'components';
import styles from './InvalidTicket.module.scss';

interface InvalidTicketProps {
  isInvalidTicket: boolean;
  onClose: () => void;
}

const InvalidTicket = ({ isInvalidTicket, onClose }: InvalidTicketProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.headerItem}>
        <ErrorIcon className={styles.invalidCircleIcon} />
        <Typography variant="bodyXl" color="error">
          {isInvalidTicket ? 'Invalid Ticket!' : 'Invalid Event!'}
        </Typography>
      </div>
      <CloseCircleIcon className={styles.headerCloseIcon} onClick={onClose} />
    </div>
  </div>
);

export default InvalidTicket;
