import { LoaderIcon } from 'assets/svg';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'consts';
import { useEffect, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLazyGetAccountQuery } from 'store';
import { useAppNavigate } from 'hooks';
import styles from './AuthCallback.module.scss';

const AuthCallback: FC = () => {
  const [getAccount] = useLazyGetAccountQuery();
  const [searchParams] = useSearchParams();
  const { navigateTo } = useAppNavigate();

  const processError = () => {
    navigateTo('LOGIN', {}, 'callbackError=true');
  };

  const processAccessData = async () => {
    try {
      await getAccount().unwrap();
      navigateTo('EVENTS');
    } catch (e) {
      processError();
    }
  };

  useEffect(() => {
    const data = searchParams.get('data');
    const errorParam = searchParams.get('error');

    if (errorParam) {
      return processError();
    }

    if (data) {
      try {
        const parsedData: {
          access: string;
          refresh: string;
        } = JSON.parse(data);
        if (parsedData.access && parsedData.refresh) {
          localStorage.setItem(ACCESS_TOKEN, parsedData.access);
          localStorage.setItem(REFRESH_TOKEN, parsedData.refresh);
          processAccessData();
        } else {
          processError();
        }
      } catch (e) {
        processError();
      }
    } else {
      processError();
    }
  }, [navigateTo]);

  return (
    <div className={styles.loaderWrapper}>
      <LoaderIcon />
    </div>
  );
};

export default AuthCallback;
