import { FocusTrap } from "focus-trap-react";
import { FC, ReactNode, useEffect } from "react";
import cn from 'classnames';
import styles from "./Modal.module.scss";
import { Button } from "components";
import { CloseIcon } from "assets/svg";

interface ModalProps {
  visible: boolean;
  children?: ReactNode;
  closable?: boolean;
  description?: string;
  title?: string | null | ReactNode;
  footer?: ReactNode | null;
  className?: string;
  width?: string | number;
  okDisabled?: boolean;
  okLoading?: boolean;
  okText?: string | null;
  cancelText?: string | null;
  onCancel?: () => void;
  onOk?: () => void;
}

const Modal: FC<ModalProps> = ({
  visible,
  children,
  description,
  title,
  footer,
  className,
  width = 640,
  closable = true,
  okDisabled = false,
  okLoading = false,
  okText = 'Confirm',
  cancelText = 'Cancel',
  onCancel,
  onOk,
}) => {
  useEffect(() => {
    if (visible) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    // Cleanup function to remove class if the component is unmounted
    return () => document.body.classList.remove('modal-open');
  }, [visible]);

  const onCloseKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCancel?.();
    }
  };
  return (
    <FocusTrap
      active={visible}
      focusTrapOptions={{
        allowOutsideClick: true,
        initialFocus: false,
      }}
    >
      <div className={cn(
        styles.modalWrapper,
        {
          [styles.visible]: visible,
        },
        className
      )}>
        <div className={styles.modal} style={{ width }}>
          {closable && <CloseIcon onKeyDown={onCloseKeyDown} tabIndex={0} className={styles.closeIcon} onClick={onCancel} />}
          {title !== null && <div className={styles.modalTitle}>{title}</div>}
          {(children || description) && (
            <div className={styles.modalMain}>
              {children || description}
            </div>
          )}
          {footer !== null && (
            <div className={styles.modalFooter}>
              {footer === undefined
                ? (
                  <>
                    {cancelText !== null && (
                      <Button
                        size="M"
                        className={styles.footerButton}
                        variant="secondary"
                        onClick={onCancel}
                      >
                        {cancelText}
                      </Button>
                    )}
                    {okText !== null && (
                      <Button
                        size="M"
                        className={styles.footerButton}
                        variant="primary"
                        loading={okLoading}
                        disabled={okDisabled}
                        onClick={onOk}
                      >
                        {okText}
                      </Button>
                    )}
                  </>
                )
                : footer
              }
            </div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
};

export default Modal;
