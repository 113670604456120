import { EventItem, Typography } from 'components';
import styles from './Discover.module.scss';
import { useGetAllEventsQuery, useGetMyEventsQuery } from 'store';
import { useCallback } from 'react';

interface DiscoverProps {
  limit?: number;
  except?: string;
}

const Discover: React.FC<DiscoverProps> = ({ limit, except }) => {
  const { data, isLoading } = useGetAllEventsQuery();
  const { data: myEvents, isLoading: isLoadingMyEvents } = useGetMyEventsQuery();

  const isEventBought = useCallback((id: string) => {
    if (!myEvents) {
      return false;
    }
    return Boolean(myEvents.find(e => e.id === id));
  }, [myEvents]);

  return (
    <div className="container">
      <div className={styles.discover}>
        <Typography variant="h5" weight="bold" className="m-b-48">
          Discover
        </Typography>
        {isLoading || isLoadingMyEvents ? (
          <div>Loading...</div>
        ) : (
          <div className={styles.discoverRow}>
            {data &&
              data
                .filter((item) => item.id !== except)
                ?.slice(0, limit)
                .map((item) => <EventItem key={item.id} event={item} bought={isEventBought(item.id)} />)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Discover;
