import { OfflineTicketEntryStatus } from 'enums';
import { handleErrorNotify } from 'helpers';
import { useVerifyMutation } from 'store';
import { verifyNotification } from '../helpers/verifyNotification';

export const useVerify = (ticketId: string, onClose: () => void) => {
  const [verify] = useVerifyMutation();

  const handleVerify = async (entryStatus: OfflineTicketEntryStatus) => {
    try {
      await verify({
        id: ticketId,
        entryStatus,
        config: { _enableErrorHandler: true },
      }).unwrap();
      verifyNotification(entryStatus);
      onClose();
    } catch (e) {
      handleErrorNotify(e);
    }
  };

  const onConfirm = async () => await handleVerify(OfflineTicketEntryStatus.Confirmed);
  const onDeny = async () => await handleVerify(OfflineTicketEntryStatus.Denied);
  const onBlock = async () => await handleVerify(OfflineTicketEntryStatus.Blocked);
  const onUnBlock = async () => await handleVerify(OfflineTicketEntryStatus.Pending);

  return { onConfirm, onDeny, onBlock, onUnBlock };
};
