import styles from './Checkbox.module.scss';
import { FC, SyntheticEvent, FormEvent, ReactNode } from 'react';
import {
  CheckIcon,
} from 'assets/svg';
import cn from 'classnames';

interface Props {
  isChecked: boolean;
  children?: ReactNode;
  className?: string;
  label?: string;
  isDisabled?: boolean;
  onChange?: (e: SyntheticEvent) => void;
}

const Checkbox: FC<Props> = ({
  isChecked,
  children,
  className,
  label,
  isDisabled = false,
  onChange,
}) => {
  const onChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  return (
    <label
      className={cn(styles.checkbox, className, {
        [styles.checked]: isChecked,
        [styles.disabled]: isDisabled,
      })}
    >
      <input
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChangeHandler}
      />
      <div
        className={cn(styles.checkboxIcon, {
          [styles.checked]: isChecked,
        })}
      >
        <CheckIcon />
      </div>
      {(children || label) && (
        <div className={styles.checkboxLabel}>{children || label}</div>
      )}
    </label>
  );
};

export default Checkbox;