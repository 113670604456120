import dayjs from 'dayjs';
import { Typography } from 'components';
import styles from './EventCountdown.module.scss';
import { useEffect, useState } from 'react';

interface EventCountdownProps {
  date: string;
}

const EventCountdown = ({ date }: EventCountdownProps) => {
  const parsedDate = dayjs(date);

  const [days, setDays] = useState<number>(() => {
    const currentDate = dayjs();
    return parsedDate.diff(currentDate, 'days');
  });
  const [hours, setHours] = useState<number>(() => {
    const currentDate = dayjs();
    return parsedDate.diff(currentDate, 'hours') % 24;
  });
  const [minutes, setMinutes] = useState<number>(() => {
    const currentDate = dayjs();
    return parsedDate.diff(currentDate, 'minutes') % 60;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = dayjs();
      const days = parsedDate.diff(currentDate, 'days');
      const hours = parsedDate.diff(currentDate.add(days, 'days'), 'hours') % 24;
      const minutes = parsedDate.diff(currentDate.add(days, 'days').add(hours, 'hours'), 'minutes') % 60;
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
    }, 1000);

    return () => clearInterval(interval);
  }, [parsedDate]);

  return (
    <div className={styles.countdown}>
      <div className={styles.countdownRow}>
        <div className={styles.countdownItem}>
          <Typography variant="h3" weight="semibold">
            {days}
          </Typography>
          <Typography variant="bodyMd" color="gray200">
            Days
          </Typography>
        </div>
        <div className={styles.countdownItem}>
          <Typography variant="h3" weight="semibold">
            {hours}
          </Typography>
          <Typography variant="bodyMd" color="gray200">
            Hours
          </Typography>
        </div>
        <div className={styles.countdownItem}>
          <Typography variant="h3" weight="semibold">
            {minutes}
          </Typography>
          <Typography variant="bodyMd" color="gray200">
            Min
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default EventCountdown;
