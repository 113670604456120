import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'helpers';
import { IReqArgs, IUser } from 'types';

export interface ISignUserData {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  country: string;
  recaptchaToken?: string;
}

// Define a service using a base URL and expected endpoints
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: getBaseQuery('/auth'),
  endpoints: (builder) => ({
    getAccount: builder.query<IUser, IReqArgs | void>({
      query: ({ config } = {}) => ({
        url: '/account',
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    requestCode: builder.mutation<any, IReqArgs<{ email: string; recaptchaToken: string }>>({
      query: ({ email, recaptchaToken, config }) => ({
        url: '/request-code',
        method: 'POST',
        body: {
          email,
          recaptchaToken,
        },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    verifyCode: builder.mutation<any, IReqArgs<{ email: string; code: string }>>({
      query: ({ email, code, config }) => ({
        url: '/verify-code',
        method: 'POST',
        body: {
          email,
          code,
        },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    signUpWithEmail: builder.mutation<any, IReqArgs<ISignUserData>>({
      query: ({ config, ...data }) => ({
        url: '/sign-up-with-email',
        method: 'POST',
        body: data,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    verifyAccess: builder.query<{ hasAccess: boolean }, IReqArgs | void>({
      query: ({ config } = {}) => ({
        url: '/account/verify-access',
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
  }),
});

export const {
  useLazyGetAccountQuery,
  useGetAccountQuery,
  useRequestCodeMutation,
  useVerifyCodeMutation,
  useSignUpWithEmailMutation,
  useVerifyAccessQuery,
} = authApi;

export default authApi.reducer;
