import cn from 'classnames';
import Typography from 'components/Typography/Typography';
import { formatDate, formatTime } from 'helpers';
import { EventType, IEvent } from 'types';
import styles from './EventItem.module.scss';
import Button from 'components/Button/Button';
import { useAppNavigate } from 'hooks';
import { EventLiveIcon, EventOfflineIcon } from 'assets/svg';
import { Link } from 'react-router';
import { ACCESS_TOKEN, ROUTES } from 'consts';

const ICONS_MAP: { [key: string]: JSX.Element } = {
  online: <EventLiveIcon />,
  offline: <EventOfflineIcon />,
};

interface IEventItemProps {
  event: IEvent;
  myEvents?: boolean;
  bought?: boolean;
}

const EventItem: React.FC<IEventItemProps> = ({ event, bought, myEvents }) => {
  const { id, title, startDate, heroBanner } = event;

  const { navigateTo } = useAppNavigate();
  const isAuth = localStorage.getItem(ACCESS_TOKEN);


  const navigateToPurchase = (id: string, ticket?: boolean) => {
    if (isAuth) {
      if (ticket) {
        navigateTo('PURCHASE_TICKET', { id });
      } else {
        navigateTo('PURCHASE', { id });
      }
    } else {
      navigateTo('LOGIN');
    }
  };

  const navigateToTicketsAllocation = (id: string) => {
    navigateTo('TICKETS_ALLOCATION', { id });
  };

  return (
    <div className={styles.event}>
      <ul className={styles.eventType}>
        <li className={cn(styles.eventTypeItem, { [styles.live]: event.live && event.type === 'online' })}>
          {ICONS_MAP[event.type]}
          {event.live && 'Live'}
        </li>
      </ul>
      <Link to={`/events/${id}`} state={myEvents ? { from: ROUTES.MY_EVENTS } : undefined} className={styles.eventImage}>
        <img src={heroBanner} alt={title} />
      </Link>
      <div className={styles.eventInfo}>
        {/* <ul className={styles.eventCategories}>
          {categories.slice(0, 2).map((category) => (
            <li className={styles.eventCategory} key={category}>
              {category}
            </li>
          ))}
          {categories.length > 2 && <li className={styles.eventCategory}>+{categories.length - 2}</li>}
        </ul> */}
        <Link to={`/events/${id}`}>
          <Typography weight="bold" variant="h6" className={cn(styles.eventTitle, 'm-b-8')}>
            {title}
          </Typography>
        </Link>
        <Typography variant="bodyS" className={styles.eventDate}>
          {formatDate(startDate)}
          <span className={styles.eventDateDivider} />
          {formatTime(startDate).toLowerCase()}
        </Typography>
      </div>
      {/* {
        myEvents ? (
          <div className={styles.actionRequired}>
            Action Required
          </div>
        ) : null
      } */}
      {!event.passed && !event.inviteOnly && !bought && (
        <div className={styles.eventBuy}>
          {[EventType.Hybrid, EventType.Online].includes(event.type) ? (
            <Button
              onClick={() => {
                navigateToPurchase(event.id);
              }}
              variant="primary"
              size="S"
              width="126px"
              className="m-r-8"
            >
              Buy Stream
            </Button>
          ) : null}
          {[EventType.Hybrid, EventType.Offline].includes(event.type) ? (
            <Button
              onClick={() => {
                navigateToPurchase(event.id, true);
              }}
              variant="secondary"
              size="S"
              width="122px">
              Buy Ticket
            </Button>
          ) : null}
        </div>
      )}
      {/* {!event.passed && !event.inviteOnly && bought && myEvents && (
        <div className={styles.eventBuy}>
          <Button
            onClick={() => {
              navigateToTicketsAllocation(event.id);
            }}
            variant="secondary"
            size="S"
          >
            Tickets allocation
            <span className={styles.allocationBudge} />
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default EventItem;
