import { Component, ComponentConfig } from "bitmovin-player-ui";
import { SupportCreator } from "components";
import { ReactNode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "store";

interface BitmovinComponentCreatorConfig extends ComponentConfig {
  children: ReactNode;
}

export default class BitmovinComponentCreator extends Component<BitmovinComponentCreatorConfig> {
  constructor({ children, ...config }: BitmovinComponentCreatorConfig) {
    super(config)
    const element = this.getDomElement().get(0);
    const root = ReactDOM.createRoot(element);
    root.render(<Provider store={store}>{children}</Provider>);
  }
}