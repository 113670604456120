import { Typography } from 'components';
import { formatDate, formatTime } from 'helpers';
import { IEvent } from 'types';
import styles from './PurchaseItem.module.scss';

interface PurchaseItemProps {
  event: Pick<IEvent, 'title' | 'startDate' | 'heroBanner' | 'price'>;
}

const PurchaseItem: React.FC<PurchaseItemProps> = ({ event }) => (
  <div className={styles.purchaseItem}>
    <div className={styles.purchaseItemName}>
      <div>
        <img src={event.heroBanner} width={128} height={72} alt={event.title} className={styles.purchaseItemImage} />
      </div>
      <div>
        <Typography variant="h7" weight="bold" className="m-b-8">
          {event.title}
        </Typography>
        <Typography variant="bodyS" className={styles.purchaseItemDate}>
          {formatDate(event.startDate)}
          <span className={styles.purchaseItemDateDivider} />
          {formatTime(event.startDate).toLowerCase()}
        </Typography>
      </div>
    </div>
  </div>
);

export default PurchaseItem;
