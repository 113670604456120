import { Button, InputField, Select, Typography } from 'components';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useMemo, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import { ISignUserData, useAppSelector, useSignUpWithEmailMutation } from 'store';
import { ACCESS_TOKEN, COUNTRIES, REFRESH_TOKEN } from 'consts';
import InputMask from 'react-input-mask';

const BIRTHDAY_FORMAT = 'YYYY/MM/DD';
const BIRTHDAY_FORMAT_REGEX = /^\d{4}\/\d{2}\/\d{2}$/;
const NAME_REGEX = /^[A-Za-z\s-]+$/;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA;

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface AboutForm {
  firstName: string;
  lastName: string;
  birthday: string;
  country: string;
}

interface AboutInfoStepProps {
  onSuccessSignUp: () => void;
}

const AboutInfoStep: FC<AboutInfoStepProps> = ({ onSuccessSignUp }) => {
  const authEmail = useAppSelector((state) => state.auth.email);
  const [signUp, { isLoading }] = useSignUpWithEmailMutation();
  const recaptchaRef = useRef<HTMLDivElement>(null);

  const schema = useMemo(
    () =>
      yup.object({
        firstName: yup
          .string()
          .trim()
          .min(1, 'First name must be at least 1 character long.')
          .max(50, 'First name cannot exceed 50 characters.')
          .matches(NAME_REGEX, 'First name can only contain letters, spaces, and hyphens.')
          .required('First name is required'),
        lastName: yup
          .string()
          .trim()
          .min(1, 'Last name must be at least 1 character long.')
          .max(50, 'Last name cannot exceed 50 characters.')
          .matches(NAME_REGEX, 'Last name can only contain letters, spaces, and hyphens.')
          .required('Last name is required'),
        birthday: yup
          .string()
          .trim()
          .required('Date of birth is required')
          .matches(BIRTHDAY_FORMAT_REGEX, `Please enter a valid date of birth.`)
          .test('validFormat', `Please enter a valid date of birth.`, (value) => {
            if (!value) return false;

            const parsedDate = dayjs(value, BIRTHDAY_FORMAT, true);
            if (!parsedDate.isValid()) return false;
            const [, month, day] = value.split('/').map(Number);
            if (month < 1 || month > 12) return false;
            const daysInMonth = parsedDate.daysInMonth(); // Get the number of days in the parsed month
            if (day < 1 || day > daysInMonth) return false;
            return true;
          })
          .test('olderThan16', 'You must be at least 16 years old to use this platform.', (value) => {
            const parsedDate = dayjs(value, BIRTHDAY_FORMAT, true);
            if (!parsedDate.isValid()) return false;
            const today = dayjs();
            const age = today.diff(parsedDate, 'year');
            return age >= 16;
          }),
        country: yup.string().required('Country is required'),
      }),
    [],
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AboutForm>({ mode: 'all', resolver: yupResolver(schema) });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleRecaptchaVerify = async (data: AboutForm) => {
    if (!window.grecaptcha) {
      console.error('reCAPTCHA not loaded');
      return;
    }

    const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });

    await onSubmit({ ...data, recaptchaToken: token });
  };

  const onSubmit = async (data: AboutForm & { recaptchaToken: string }) => {
    if (authEmail) {
      try {
        const signUpData: ISignUserData = {
          email: authEmail,
          firstName: data.firstName,
          lastName: data.lastName,
          dateOfBirth: dayjs(data.birthday, 'YYYY/MM/DD').format('YYYY-MM-DD'),
          country: data.country,
          recaptchaToken: data.recaptchaToken,
        };
        const response = await signUp({ ...signUpData, config: { _enableErrorHandler: true } }).unwrap();
        if (response.access) {
          localStorage.setItem(ACCESS_TOKEN, response.access);
          localStorage.setItem(REFRESH_TOKEN, response.refresh);
          onSuccessSignUp();
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(handleRecaptchaVerify)}>
      <Typography align="center" className="m-b-12" variant="h4" weight="semibold">
        About you
      </Typography>
      <Typography align="center" className="m-b-12" color="gray300" variant="bodyS">
        Welcome to ENTX! We’re so glad you’re here. Before you can unlock the benefits of the ENTX ecosystem, tell us a
        bit more about you…
      </Typography>
      <Controller
        name="firstName"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
          <InputField
            label="First Name"
            placeholder="Enter your first name"
            className="m-b-12"
            onChange={onChange}
            onBlur={onBlur}
            invalid={isTouched && invalid}
            errorText={error?.message}
            value={value}
          />
        )}
      />
      <Controller
        name="lastName"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
          <InputField
            label="Last Name"
            placeholder="Enter your last name"
            className="m-b-12"
            onChange={onChange}
            onBlur={onBlur}
            invalid={isTouched && invalid}
            errorText={error?.message}
            value={value}
          />
        )}
      />
      <Controller
        name="birthday"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
          <InputMask mask="9999/99/99" value={value} onChange={onChange} onBlur={onBlur} placeholder={BIRTHDAY_FORMAT}>
            {(inputProps) => (
              <InputField
                className="m-b-12"
                {...inputProps}
                label="Date of Birth"
                invalid={isTouched && invalid}
                isReturnEvent
                errorText={error?.message}
              />
            )}
          </InputMask>
        )}
      />
      <Controller
        name="country"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error, invalid, isTouched } }) => (
          <Select
            withSearch
            label="Country"
            placeholder="Select your country"
            className="m-b-48"
            options={COUNTRIES}
            onChange={onChange}
            invalid={isTouched && invalid}
            errorText={error?.message}
            value={value}
          />
        )}
      />
      <Button type="submit" variant="primary" width="100%" disabled={!isValid} loading={isLoading} size="M">
        Join Now
      </Button>
      <div ref={recaptchaRef} />
    </form>
  );
};

export default AboutInfoStep;
