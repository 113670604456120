import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { BaseQueryError } from "./rtk";
import { SerializedError } from "@reduxjs/toolkit";
import { notify } from "helpers";

export const getRtkErrorMessage = (error?: BaseQueryError | FetchBaseQueryError | SerializedError | unknown): string => {
  if (error && typeof error === "object" && "data" in error && error.data && typeof error.data === "object" && "message" in error.data) {
    return typeof error.data.message === "string" ? error.data.message : "";
  }
  return "";
}

export const checkIfErrorStatusWithinCodes = (error: BaseQueryError | FetchBaseQueryError | SerializedError | unknown, codes: number[]) => {
  if (error && typeof error === "object" && "status" in error && codes.includes(Number(error.status))) {
    return true;
  }
  return false;
}

export const handleErrorNotify = (error?: BaseQueryError | FetchBaseQueryError | SerializedError | unknown) => {
  notify.error(getRtkErrorMessage(error) || "Something went wrong! Please try again later");
}