import ButtonLink from 'components/Button/ButtonLink';

const LoginButtons = () => {
  return (
    <div>
      <ButtonLink variant="secondary" to="/login" width="98px" size="S" className="m-r-16">
        Log In
      </ButtonLink>
      <ButtonLink variant="primary" width="116px" size="S" to="/signup">
        Join Now
      </ButtonLink>
    </div>
  );
};

export default LoginButtons;
