import { UUID } from 'node:crypto';


export enum SumsubReviewStatus {
  INIT = 'init',
  COMPLETED = 'completed',
  ON_HOLD = 'onHold',
  PENDING = 'pending',
}
export interface IUser {
  id: UUID;
  email: string;
  type: string;
  role: string;
  firstName: string;
  lastName: string;
  refreshToken: string;
  socialProviderId: string | null;
  socialPictureUrl: string | null;
  stripeCustomerId: string | null;
  createdAt: string;
  sumsub?: {
    reviewStatus: SumsubReviewStatus,
  }
}
