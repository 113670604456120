import { FC, useCallback, useEffect, useState } from 'react';
import { Button, InputFieldOTP, Typography } from 'components';
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'consts';
import { checkIfErrorStatusWithinCodes, getRtkErrorMessage, handleErrorNotify } from 'helpers';
import { useAppNavigate } from 'hooks';
import { useAppSelector, useRequestCodeMutation, useVerifyCodeMutation } from 'store';

interface VerifyEmailProps {
  onRegisterRequire: () => void;
}

const VerifyEmailStep: FC<VerifyEmailProps> = ({ onRegisterRequire }) => {
  const authEmail = useAppSelector((state) => state.auth.email);
  const { navigateTo } = useAppNavigate();
  const [otp, setOtp] = useState('');
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendTimerActive, setIsResendTimerActive] = useState(true);
  const [
    verifyCode,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessVerify,
      error: errorVerify,
      isError: isErrorVerify,
      reset: resetVerify,
    },
  ] = useVerifyCodeMutation();
  const [resendCode, { isLoading: isLoadingResend, isError: isErrorResend, error: errorResend, isSuccess: isResendSuccess }] = useRequestCodeMutation();

  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => console.log("Invisible reCAPTCHA loaded"));
      }
    };
  }, []);

  useEffect(() => {
    if (isResendTimerActive && resendTimer > 0) {
      const interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (resendTimer === 0) {
      setIsResendTimerActive(false);
    }
  }, [resendTimer, isResendTimerActive]);

  const executeRecaptcha = async (action: string) => {
    return window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action });
  };

  const onOTPVerifyCode = useCallback(async () => {
    if (authEmail) {
      try {
        const response = await verifyCode({
          email: authEmail,
          code: otp,
        }).unwrap();
        setTimeout(() => {
          if (response.access) {
            localStorage.setItem(ACCESS_TOKEN, response.access);
            localStorage.setItem(REFRESH_TOKEN, response.refresh);
            //navigate to my events
            navigateTo('EVENTS');
          } else {
            onRegisterRequire();
          }
        }, 500);
      } catch (e) {
        if (!checkIfErrorStatusWithinCodes(e, [400, 403])) {
          resetVerify();
          handleErrorNotify(e);
        }
      }
    }
  }, [authEmail, otp]);

  const resendEmail = useCallback(async () => {
    if (authEmail) {
      try {
        const recaptchaToken = await executeRecaptcha("resend");

        await resendCode({ email: authEmail, recaptchaToken }).unwrap();
        setResendTimer(60);
        setIsResendTimerActive(true);
      } catch (e) {
        if (!checkIfErrorStatusWithinCodes(e, [403])) {
          handleErrorNotify(e);
        }
      }
    }
  }, [authEmail]);

  const onOTPChange = useCallback(
    (value: string) => {
      setOtp(value);
      if (isErrorVerify) {
        resetVerify();
      }
    },
    [isErrorVerify],
  );

  return (
    <>
      <Typography className="m-b-12" variant="h4" weight="semibold">Verify your email</Typography>
      {
        isResendSuccess ? (
          <Typography className="m-b-32" color="gray300" variant="bodyS">
            A new 6-digit code has been sent to <span className="c-white fw-600">{authEmail}</span>, please check your inbox, including the spam/junk folder.
          </Typography>

        ) : (
          <Typography className="m-b-32" color="gray300" variant="bodyS">
            Please enter the 6-digit confirmation code we’ve just sent to <span className="c-white fw-600">{authEmail}</span>.
          </Typography>
        )
      }
      <InputFieldOTP
        success={isSuccessVerify}
        className="m-b-32"
        value={otp}
        invalid={isErrorVerify && checkIfErrorStatusWithinCodes(errorVerify, [400, 403])}
        errorText={checkIfErrorStatusWithinCodes(errorVerify, [400, 403]) && getRtkErrorMessage(errorVerify)}
        onChange={onOTPChange}
        numInputs={6}
      />
      <Button variant="primary" size="M" width="100%" className="m-b-32" disabled={!otp} loading={isLoadingVerify || isSuccessVerify} onClick={onOTPVerifyCode}>
        Verify
      </Button>
      <Typography color="gray300" variant="bodyS" align="center">
        Didn’t get the code? <Button variant="link" disabled={isLoadingResend || isResendTimerActive} size="XS" onClick={resendEmail}>Resend{isResendTimerActive ? ` (${resendTimer})` : ''}</Button>
      </Typography>
      {isErrorResend && checkIfErrorStatusWithinCodes(errorResend, [403]) && getRtkErrorMessage(errorResend) && (
        <Typography className="m-t-12" color="error" variant="bodyS" align="center">
          {getRtkErrorMessage(errorResend)}
        </Typography>
      )}
    </>
  );
};

export default VerifyEmailStep;
