import { OfflineTicketEntryStatus } from 'enums';
import { notify } from 'helpers';

export const verifyNotification = (status: OfflineTicketEntryStatus) => {
  if (status === OfflineTicketEntryStatus.Confirmed) {
    notify.success('Check in successful!');
  }
  if (status === OfflineTicketEntryStatus.Denied) {
    notify.warning('Entry has been denied!');
  }
  if (status === OfflineTicketEntryStatus.Blocked) {
    notify.warning('Guest has been blocked!');
  }
  if (status === OfflineTicketEntryStatus.Pending) {
    notify.success('Guest unblocked successfully!');
  }
};
