import { UUID } from 'node:crypto';

export enum EventType {
  Online = 'online',
  Offline = 'offline',
  Hybrid = 'hybrid',
}

export interface IEventStream {
  createdAt: string;
  dashManifestUrl: string;
  finishedAt: string;
  hlsManifestUrl: string;
  status: string;
}

export interface IEvent {
  id: UUID;
  title: string;
  brief: string | null;
  description: string | null;
  venue: string | null;
  heroBanner: string;
  startDate: string;
  duration: number;
  price: number;
  artists: IArtist[];
  ownerId: string;
  type: EventType;
  live?: boolean;
  stream?: IEventStream;
  streamId?: string | null;
  streamUrl?: string | null;
  passed?: boolean;
  created_at?: string;
  updated_at?: string;
  inviteOnly?: boolean;
}

export interface IArtist {
  id: UUID;
  title: string;
  role: string;
  image?: string;
  created_at?: string;
  updated_at?: string;
}
