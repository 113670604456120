import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'helpers';
import { IReqArgs, IEvent } from 'types';

interface IEventResponse {
  events: IEvent[];
  eventsCount: number;
}

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: getBaseQuery('/events'),
  endpoints: (builder) => ({
    getAllEvents: builder.query<IEvent[], IReqArgs<{ limit?: string; offset?: string }> | void>({
      query: ({ limit = 24, offset = 0, config, } = {}) => ({
        url: `?limit=${limit}&offset=${offset}`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
      transformResponse: (response: IEventResponse) => {
        return (
          response.events.map((event) => ({
            ...event,
            passed: new Date(event.startDate) < new Date(),
            live: new Date(event.startDate) < new Date() && new Date(event.startDate) > new Date(),
          })) || []
        );
      },
    }),
    getOneEvent: builder.query<IEvent, IReqArgs<{ id: string }>>({
      query: ({ id, config }) => ({
        url: `/${id}`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
      transformResponse: (response: IEvent) => {
        const passed = new Date(response.startDate) < new Date();
        return { ...response, passed };
      },
    }),
    getMyEvents: builder.query<IEvent[], IReqArgs<{ limit?: string; offset?: string }> | void>({
      query: ({ limit = 24, offset = 0, config } = {}) => ({
        url: `/purchased?limit=${limit}&offset=${offset}`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
      transformResponse: (response: IEventResponse) => {
        return response.events;
      },
    }),
    getUpcomingEvent: builder.query<IEvent, IReqArgs | void>({
      query: ({ config } = {}) => ({
        url: '/upcoming-event',
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
  }),
});

export const {
  useGetAllEventsQuery,
  useLazyGetOneEventQuery,
  useLazyGetMyEventsQuery,
  useGetOneEventQuery,
  useGetMyEventsQuery,
  useGetUpcomingEventQuery,
} = eventApi;
