import cn from 'classnames';
import styles from './Button.module.scss';
import { FC, ReactNode } from 'react';
import { LoaderIcon } from 'assets/svg';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'link';

export type ButtonSize = 'L' | 'M' | 'S' | 'XS';

export interface ButtonProps {
  id?: string;
  variant?: ButtonVariant;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  circle?: boolean;
  type?: 'button' | 'submit' | 'reset';
  width?: string;
  size?: ButtonSize;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  children: ReactNode;
}

const Button: FC<ButtonProps> = ({
  id,
  variant,
  iconLeft,
  iconRight,
  circle,
  type = 'button',
  width,
  size,
  onClick,
  className,
  disabled,
  loading,
  children,
}) => (
  <button
    id={id}
    className={cn(
      styles.button,
      variant && styles[variant],
      iconLeft && styles.withIcon,
      iconRight && styles.withIcon,
      disabled && styles.disabled,
      loading && styles.loading,
      circle && styles.circle,
      size && styles[`size${size}`],
      className,
    )}
    type={type}
    style={width ? { width, padding: 0 } : {}}
    onClick={onClick}
    disabled={disabled || loading}>
    {iconLeft && <span className={styles.buttonIcon}>{iconLeft}</span>}
    {loading ? <LoaderIcon /> : children}
    {iconRight && <span className={styles.buttonIcon}>{iconRight}</span>}
  </button>
);

export default Button;
