export const priceFormatter = (price: number, inCents: boolean = true) => {
  if (!price) return '$0.00';

  const priceInPounds = inCents ? price / 100 : price;

  return priceInPounds.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};
