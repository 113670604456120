import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { EventType, IEvent } from 'types';
import { Button, ButtonLink, Typography } from 'components';
import {
  checkIfErrorStatusWithinCodes,
  formatDateLong,
  formatTime,
  notify,
  priceFormatter
} from 'helpers';
import EventCountdown from '../EventCountdown/EventCountdown';
import { ACCESS_TOKEN } from 'consts';
import { useAppNavigate } from 'hooks';
import { TicketIcon } from 'assets/svg';
import styles from './EventHeader.module.scss';
import { useCheckEventStatusQuery, useLazyGetMyEventsQuery, useLazyGetStreamAccessQuery } from 'store';
import dayjs from 'dayjs';
import { StreamStatus } from 'enums';

interface EventHeaderProps {
  event: IEvent;
}

const EventHeader = ({ event }: EventHeaderProps) => {
  const { navigateTo } = useAppNavigate();
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const { data, isError, isLoading } = useCheckEventStatusQuery({ id: event.id }, { skip: !accessToken });
  const [getStreamAccess] = useLazyGetStreamAccessQuery();
  const [getMyEvents, { data: myEvents, isLoading: isLoadingMyEvents }] = useLazyGetMyEventsQuery();

  const getMyEventsReq = async () => {
    await getMyEvents();
  }

  const bought = event.type === EventType.Online ? !isError && data?.status === 'completed' : myEvents?.find(e => e.id === event.id)

  const [resendLoading, setResendLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isResendTimerActive, setIsResendTimerActive] = useState(false);
  const [isResetCounter, setIsResetCounter] = useState(false);

  useEffect(() => {
    if (accessToken) {
      getMyEventsReq();
    }
  }, []);

  useEffect(() => {
    if (isResendTimerActive && resendTimer > 0) {
      const interval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
        setResendLoading(false);
      }, 1000);

      return () => clearInterval(interval);
    } else if (resendTimer === 0) {
      setIsResendTimerActive(false);
    }
  }, [resendTimer, isResendTimerActive]);

  const navigateToPurchase = (id: string, ticket?: boolean) => {
    if (accessToken) {
      if (ticket) {
        navigateTo('PURCHASE_TICKET', { id });
      } else {
        navigateTo('PURCHASE', { id });
      }
    } else {
      navigateTo('LOGIN');
    }
  };

  const navigateToTicketsAllocation = (id: string) => {
    navigateTo('TICKETS_ALLOCATION', { id });
  };

  const getMinutesToStartDate = useCallback(() => {
    return dayjs(event.startDate).diff(dayjs(), "minutes");
  }, [event])


  const resendAccess = async () => {
    const { isError, isSuccess, error } = await getStreamAccess({ eventId: event.id })
    setResendLoading(true);

    if (isSuccess) {
      notify.success('Access email resent successfully.');
    }

    if (isError) {
      if (checkIfErrorStatusWithinCodes(error, [429])) {
        setIsResetCounter(true)
      } else {
        notify.error('Something went wrong');
      }
    }
    setTimeout(() => {
      setIsResendTimerActive(true);
      setResendTimer(60);
      setResendLoading(false);
    }, 1000);
  };

  return (
    <section className={styles.header}>
      <div className="container">
        <div className={styles.headerBlur}>
          <img src={event.heroBanner} width={676} height={473} alt={event.title} />
        </div>
        <div className={styles.headerContainer}>
          <div className={styles.headerImage}>
            <img src={event.heroBanner} width={628} height={377} alt={event.title} />
            {!event.passed && <EventCountdown date={event.startDate} />}
          </div>
          <div className={styles.headerEventTitle}>
            {/* {bought
              ? (
                <div className={styles.actionRequired}>
                  Action Required
                </div>
              ) : null
            } */}
            <Typography variant="h3" weight="semibold" className="m-b-8">
              {event.title}
            </Typography>
            <Typography variant="bodyS" className={cn(styles.headerDate, 'm-b-32')}>
              {formatDateLong(event.startDate)}
              <span className={styles.headerDateDivider} />
              {formatTime(event.startDate).toLowerCase()}
            </Typography>
            {event.type !== 'offline' ? (<Typography variant="bodyS" className="m-b-4" color="gray100">
              Streaming live from {event.venue}
            </Typography>) : null}
            <Typography variant="bodyMd" weight="medium" className="m-b-40" color="gray100">
              {event.brief}
            </Typography>
            {!event.passed && !event.inviteOnly && !bought && !isLoading && (
              <div className={styles.headerBuy}>
                <div className={styles.headerBuyRow}>
                  {[EventType.Hybrid, EventType.Online].includes(event.type) ? (
                    <Button
                      onClick={() => {
                        navigateToPurchase(event.id);
                      }}
                      variant="primary"
                      className={styles.headerBuyButton}
                      size="M"
                      width="208px">
                      Buy Stream <span /> {priceFormatter(event.price)}
                    </Button>
                  ) : null}
                  {[EventType.Hybrid, EventType.Offline].includes(event.type) ? (
                    <Button
                      onClick={() => {
                        navigateToPurchase(event.id, true);
                      }}
                      className={cn(styles.headerBuyButton, styles.secondary)}
                      variant="secondary"
                      size="M"
                      width="196px">
                      Buy Ticket <span /> {priceFormatter(event.price)}
                    </Button>
                  ) : null}

                </div>
                <Typography variant="bodyXs" color="gray100">
                  To watch this live stream, you’ll need to purchase viewing access
                </Typography>
              </div>
            )}
            {/* {!event.passed && bought && !isLoading ? (
              <Button
                onClick={() => {
                  navigateToTicketsAllocation(event.id);
                }}
                variant="secondary"
                size="M"
                iconLeft={<TicketIcon />}
              >
                Tickets Allocation
                <span className={styles.allocationBudge} />
              </Button>
            ) : null} */}
            {!event.passed && bought && !isLoading && getMinutesToStartDate() < 180 && (
              <div className={styles.eventBuy}>
                <Button
                  onClick={resendAccess}
                  variant="primary"
                  className="m-b-16"
                  size="M"
                  width="174px"
                  loading={resendLoading}
                  disabled={isResendTimerActive || isResetCounter || resendLoading}>
                  Resend Access
                </Button>
                {isResendTimerActive && !isResetCounter && (
                  <Typography variant="bodyXs" color="gray300">
                    You can resend email in <b>00:{resendTimer < 10 ? `0${resendTimer}` : resendTimer}</b> seconds
                  </Typography>
                )}
                {isResetCounter && (
                  <Typography variant="bodyXs" color="error">
                    You've reached the maximum resend attempts for now. Please try again later.
                  </Typography>
                )}
              </div>
            )}
            {bought && !isLoading && event.stream?.status === StreamStatus.FINISHED && (
              <div className={styles.eventBuy}>
                <ButtonLink
                  to='stream'
                  variant="primary"
                  className="m-b-16"
                  size="M"
                  width="174px"
                >
                  Watch stream
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventHeader;
