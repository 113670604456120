import { loadStripe } from '@stripe/stripe-js';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { notify } from 'helpers';
import { useGetOneEventQuery, useCheckoutMutation, useAppSelector, useGetAccountQuery, useReserveTicketMutation, usePurchaseTicketMutation } from 'store';
import { ROUTES } from 'consts';
import { StripePayment, Typography, Breadcrumbs, Countdown } from 'components';
import { priceFormatter } from 'helpers';
import styles from './PurchaseProcess.module.scss';
import { EventType, SumsubReviewStatus } from 'types';
import { useAppNavigate } from 'hooks';
import TimesUp from './TimesUp/TimesUp';

interface PurchaseProcessProps {
  ticket?: boolean;
}

const PurchaseProcess: FC<PurchaseProcessProps> = ({ ticket }) => {
  const { id } = useParams<{ id: string }>();
  const { navigateTo } = useAppNavigate();
  const location = useLocation();
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery();
  const { data: event, isLoading: eventLoading } = useGetOneEventQuery({ id: id! });

  const shouldShowKyc = useMemo(() => {
    return event?.type !== EventType.Online && (!account?.sumsub || account?.sumsub.reviewStatus !== SumsubReviewStatus.COMPLETED);
  }, [account, event]);

  useEffect(() => {
    if (!location.state || location.state.from !== ROUTES.PURCHASE) {
      navigateTo('EVENTS');
    }
  }, [location])

  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const [checkout] = useCheckoutMutation();
  const [reserveTicket] = useReserveTicketMutation();
  const [purchaseTicket] = usePurchaseTicketMutation();

  const [timeLeft, setTimeLeft] = useState(0);

  const [timesUp, setTimesUp] = useState(false);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!));
  }, []);

  useEffect(() => {
    const handleGetIntent = async () => {
      try {
        let clientSecret = null;
        if (ticket) {
          if (shouldShowKyc) {
            clientSecret = await reserveTicket({
              eventId: event?.id!,
              quantity: location.state.quantity,
            });
          } else {
            clientSecret = await purchaseTicket({
              eventId: event?.id!,
              quantity: location.state.quantity,
            });
          }
        } else {
          clientSecret = await checkout({
            eventId: event?.id!,
          });
        }
        // is ticket, has kyc 
        if (clientSecret?.data) {
          setClientSecret(clientSecret.data.clientSecret);
        }
      } catch (error) {
        notify.error('Failed to get payment intent');
      }
    };
    if (event && account && stripePromise && !clientSecret) {
      handleGetIntent();
    }
  }, [event, stripePromise, checkout]);

  const handlePay = () => {
    if (ticket) {
      if (shouldShowKyc) {
        navigateTo(
          'PURCHASE_TICKET_PROCESS_SUMSUB',
          { id: id! },
          undefined,
          { from: ROUTES.PURCHASE_TICKET_PROCESS, quantity: location.state.quantity, timeLeft }
        );
      } else {
        navigateTo(
          'PURCHASE_TICKET_COMPLETED',
          { id: id! },
          undefined,
          { from: ROUTES.PURCHASE_TICKET_PROCESS, quantity: location.state.quantity }
        );
      }
    } else {
      navigateTo(
        'PURCHASE_COMPLETED',
        { id: id! },
      );
    }
  };

  const handleTimesUp = () => {
    setTimesUp(true);
  }


  if (!id) {
    return <div>Event not found</div>;
  }

  // console.log(timeLeft);

  if (timesUp && ticket) {
    return <TimesUp id={id} />
  }

  if (eventLoading || isAccountLoading) {
    return <div>Loading...</div>;
  }


  return (
    <>
      <div className="container">
        <div className={styles.countdown}>
          <Breadcrumbs title="Summary" to={(ticket ? ROUTES.PURCHASE_TICKET : ROUTES.PURCHASE).replace(':id', id)} />
          {ticket ? (
            <Countdown initialTime={600} onEnd={handleTimesUp} onTimeChange={(time) => setTimeLeft(time)} />
          ) : null}
        </div>
      </div>
      <div className={styles.purchase}>
        <Typography variant="h4" weight="medium" className="m-b-48">
          Payment
        </Typography>
        <div className={styles.purchaseTotal}>
          <Typography variant="h7" weight="semibold">
            Total:
          </Typography>
          <Typography variant="bodyL" weight="medium">
            {priceFormatter(event?.price! * location.state.quantity)}
          </Typography>
        </div>
        <StripePayment buttonText={ticket ? "Buy Ticket" : "Buy Stream"} clientSecret={clientSecret} stripe={stripePromise} onSubmit={handlePay} addressElement />
      </div>
    </>
  );
};

export default PurchaseProcess;
