import { configureStore } from '@reduxjs/toolkit';
import { eventApi } from './api/event';
import { paymentApi } from './api/payment';
import { authApi } from './api/auth';
import { sumsubApi } from './api/sumsub';
import { streamApi } from './api/stream';
import { ticketApi } from './api/ticket';
import authReducer from './slices/auth';
import eventReducer from './slices/event';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [sumsubApi.reducerPath]: sumsubApi.reducer,
    [streamApi.reducerPath]: streamApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    auth: authReducer,
    event: eventReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      eventApi.middleware,
      paymentApi.middleware,
      sumsubApi.middleware,
      streamApi.middleware,
      ticketApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
