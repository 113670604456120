import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Button.module.scss';
import { ButtonProps } from './Button';
import { FC } from 'react';

interface ButtonLinkProps
  extends Pick<
    ButtonProps,
    'variant' | 'width' | 'size' | 'circle' | 'iconLeft' | 'iconRight' | 'children' | 'className' | 'disabled'
  > {
  to: string;
}

const ButtonLink: FC<ButtonLinkProps> = ({
  to,
  variant,
  width,
  size,
  circle,
  iconLeft,
  iconRight,
  className,
  disabled,
  children,
}) => {
  return (
    <Link
      className={cn(
        styles.button,
        styles.nav,
        variant && styles[variant],
        iconLeft && styles.withIcon,
        iconRight && styles.withIcon,
        disabled && styles.disabled,
        circle && styles.circle,
        size && styles[`size${size}`],
        className,
      )}
      style={width ? { width: width, padding: 0 } : {}}
      to={to}>
      {iconLeft && <span className={styles.buttonIcon}>{iconLeft}</span>}
      {children}
      {iconRight && <span className={styles.buttonIcon}>{iconRight}</span>}
    </Link>
  );
};

export default ButtonLink;
