import { Navigate, useLocation, useNavigate, useParams } from 'react-router';
import { useAppSelector, useGetOneEventQuery, useLazyGetMyEventsQuery } from 'store';
import { ACCESS_TOKEN, ROUTES } from 'consts';
import EventHeader from './components/EventHeader/EventHeader';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import EventInfo from './components/EventInfo/EventInfo';
import Discover from 'pages/Events/components/Discover/Discover';
import { useEffect, useMemo } from 'react';
import { notify } from 'helpers';

const EventDetails = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const [getMyEvents] = useLazyGetMyEventsQuery();
  const fromMyEvents = useMemo(() => {
    return location.state?.from === ROUTES.MY_EVENTS;
  }, [location]);

  const { data, isLoading } = useGetOneEventQuery({ id: id! });
  const { isVerified } = useAppSelector((state) => state.auth);

  const getMyEventsReq = async () => {
    await getMyEvents();
  }

  useEffect(() => {
    if (accessToken) {
      getMyEventsReq();
    }
  }, []);

  useEffect(() => {
    if (!isVerified) {
      notify.info(
        'We noticed you haven’t verified your ID yet. Please complete this step to be able to purchase events.',
      );
    }
  }, [isVerified]);

  if (!data && !isLoading) {
    return <Navigate to={fromMyEvents ? ROUTES.MY_EVENTS : ROUTES.EVENTS} />;
  }

  return (
    <div>
      <div className="container">
        {fromMyEvents
          ? <Breadcrumbs title="My events" to={ROUTES.MY_EVENTS} />
          : <Breadcrumbs title="All events" to={ROUTES.EVENTS} />
        }
      </div>
      {!isLoading && data && (
        <>
          <EventHeader event={data} />
          <EventInfo event={data} />
          <Discover limit={3} except={id} />
        </>
      )}
    </div>
  );
};

export default EventDetails;
