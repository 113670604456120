import { ButtonLink, Typography } from 'components';
import styles from './TimesUp.module.scss';
import { ROUTES } from 'consts';

interface TimesUpProps {
  id: string;
}

const TimesUp = ({ id }: TimesUpProps) => {
  return (
    <div className={styles.timesUp}>
      <Typography variant="h2" weight="semibold" className="m-b-16">
        Time’s Up!
      </Typography>
      <Typography variant="bodyMd" color="gray300" className="m-b-48">
        Your reservation expired. Please start over to secure your tickets.
      </Typography>
      <ButtonLink variant="primary" size="L" width="200px" to={ROUTES.EVENT.replace(':id', id)}>
        Back to Event
      </ButtonLink>
    </div>
  );
};

export default TimesUp;
