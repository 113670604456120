import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Link.module.scss';

interface Props {
  to: string;
  external?: boolean;
  className?: string;
  children: React.ReactNode;
}

const LinkComponent = ({ to, external, className, children }: Props) => {
  if (external) {
    return (
      <a
        href={to}
        target={external && '_blank'}
        rel={external && 'noopener noreferrer'}
        className={cn(styles.link, {
          [className || '']: className,
        })}>
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      className={cn(styles.link, {
        [className || '']: className,
      })}>
      {children}
    </Link>
  );
};

export default LinkComponent;
