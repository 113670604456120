import { FC, useEffect, useRef, useState } from 'react';
import { Breadcrumbs, ButtonLink, Countdown, Typography } from 'components';
import styles from './SumSub.module.scss';
import SumSubSDK from '@sumsub/websdk';
import { useGetUserAccessTokenQuery, useLazyGetOneEventQuery } from "../../store";
import { useLocation, useParams } from 'react-router';
import { ROUTES } from 'consts';
import { useAppNavigate } from 'hooks';
import cn from 'classnames';
import TimesUp from 'pages/PurchaseProcess/TimesUp/TimesUp';
import { SumsubReviewStatus } from 'types';

interface SubsubProps {
  eventOnly?: boolean;
}

interface ApplicationStatusPayload {
  reviewStatus?: SumsubReviewStatus
}

const Sumsub: FC<SubsubProps> = ({ eventOnly }) => {
  const { id } = useParams<{ id: string }>();
  const kycContainer = useRef(null);
  const [showGoToEvents, setShowGoToEvents] = useState(false);
  const { navigateTo } = useAppNavigate();
  const [getEventData, { data: event, isLoading: isLoadingEvent, error }] = useLazyGetOneEventQuery();
  const { data, isLoading } = useGetUserAccessTokenQuery(undefined, { refetchOnMountOrArgChange: true });

  const location = useLocation();

  const getEventDataReq = async () => {
    await getEventData({ id: id! });
  }

  useEffect(() => {
    if (eventOnly) {
      getEventDataReq();
    }
  }, []);

  const handleApplicationStatusChange = ({ reviewStatus }: ApplicationStatusPayload = {}) => {
    if (reviewStatus === SumsubReviewStatus.COMPLETED) {
      if (eventOnly) {
        navigateTo(
          'PURCHASE_TICKET_COMPLETED',
          { id: id! },
          undefined,
          { from: ROUTES.PURCHASE_TICKET_PROCESS_SUMSUB, quantity: location.state?.quantity || 1 }
        );
      } else {
        setShowGoToEvents(true)
      }
    }
  }

  useEffect(() => {
    if (eventOnly && (!location.state || location.state.from !== ROUTES.PURCHASE_TICKET_PROCESS)) {
      navigateTo('EVENTS');
    }
  }, [location]);

  const [timesUp, setTimesUp] = useState(false);

  useEffect(() => {
    const initSDK = async () => {
      if (!data || (eventOnly && !event)) return;

      try {
        // Initialize WebSDK
        const sdk = SumSubSDK.init(
          data,
          () => {
            console.log('SDK is ready');
            return Promise.resolve('SDK is ready');
          },
        ).withConf({
          theme: 'dark',
          lang: 'en'

        })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on("idCheck.onApplicantStatusChanged", (payload) => {
            handleApplicationStatusChange(payload as ApplicationStatusPayload)
          })
          .on("idCheck.onError", (error) => {
            console.log("onError", error);
          }).build()

        if (kycContainer.current) {
          sdk.launch(kycContainer.current);
        }
      } catch (error) {
        console.error('Error initializing Sumsub SDK:', error);
      }
    };

    initSDK();
  }, [data, event]);

  const handleTimesUp = () => {
    setTimesUp(true);
  }

  if (!id && eventOnly) {
    return <div>Event not found</div>;
  }

  if (isLoadingEvent || isLoading) {
    return <div>Loading...</div>;
  }

  if (timesUp && id && eventOnly) {
    return <TimesUp id={id} />
  }

  return (
    <>
      {eventOnly && id ? (
        <div className="container">
          <div className={styles.countdown}>
            <Breadcrumbs title="Summary" to={ROUTES.PURCHASE_TICKET.replace(':id', id)} />
            <Countdown initialTime={location.state?.timeLeft || 600} onEnd={handleTimesUp} />
          </div>
        </div>
      ) : null}
      <div className={styles.sumsubBlock}>
        <Typography variant="h4" weight="bold" className={cn("m-b-48", { "m-t-48": !eventOnly })}>Identity Verification</Typography>
        <div className={styles.sumsub}>
          <div ref={kycContainer} style={{ width: '100%' }}></div>
          {showGoToEvents ? (
            <div className={styles.navigateButton}>
              <ButtonLink
                to={ROUTES.EVENTS}
                variant="primary"
                size="M"
              >
                Go to Events
              </ButtonLink>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Sumsub;
