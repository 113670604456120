import cn from 'classnames';
import { IEvent } from 'types';
import { Typography } from 'components';
import { formatDateLong, formatTime } from 'helpers';
import styles from './StreamHeader.module.scss';
import { ShowIcon } from 'assets/svg';
import { FC } from 'react';

interface StreamHeaderProps {
  event: IEvent;
}

const StreamHeader: FC<StreamHeaderProps> = ({ event }) => {
  return (
    <section className={styles.header}>
      <div className="container">
        <Typography variant="bodyS" className={cn(styles.headerDate, 'm-b-24')}>
          {formatDateLong(event.startDate)}
          <span className={styles.headerDateDivider} />
          {formatTime(event.startDate).toLowerCase()}
        </Typography>
        <Typography variant="h3" weight="semibold" className="m-b-8">
          {event.title}
        </Typography>
        {/* <div className={styles.watchBlock}>
          <ShowIcon className={styles.showIcon} />
          <Typography variant="bodyS" color="gray300">
            95K watching
          </Typography>
        </div> */}
      </div>
    </section>
  );
};

export default StreamHeader;
