import { Elements } from '@stripe/react-stripe-js';
import { FC, ReactNode, useState } from 'react';
import PaymentForm from './PaymentForm';
import { Appearance, Stripe } from '@stripe/stripe-js';
import { LoaderIcon } from 'assets/svg';
import styles from './StripePayment.module.scss';

interface StripePaymentProps {
  clientSecret: string | null;
  stripe: PromiseLike<Stripe | null> | Stripe | null;
  buttonText?: string;
  additionalButton?: ReactNode;
  buttonsClassName?: string;
  addressElement?: boolean;
  onSubmit: () => void;
}

const appearance: Appearance = {
  theme: 'night',
  variables: {
    fontFamily: `"Kanit", sans-serif`,
    colorBackground: '#1b1b24',
    colorPrimary: '#ebff22',
    colorText: '#fff',
    colorSuccess: '#42a148',
    colorDanger: '#dc3220',
  },
};

const StripePayment: FC<StripePaymentProps> = ({
  onSubmit,
  clientSecret,
  stripe,
  buttonText = 'Buy',
  additionalButton,
  addressElement,
  buttonsClassName,
}) => {
  if (!clientSecret) {
    return (
      <div className={styles.loaderWrapper}>
        <LoaderIcon />
      </div>
    );
  }
  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: clientSecret ?? undefined,
        appearance,
      }}>
      <PaymentForm
        clientSecret={clientSecret}
        onSubmit={onSubmit}
        buttonText={buttonText}
        additionalButton={additionalButton}
        buttonsClassName={buttonsClassName}
        addressElement={addressElement}
      />
    </Elements>
  );
};

export default StripePayment;
