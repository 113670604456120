import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

interface CountdownProps {
  initialTime: number;
  onEnd?: () => void;
  onTimeChange?: (time: number) => void;
}

const Countdown = ({ initialTime, onEnd, onTimeChange }: CountdownProps) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeLeft]);

  useEffect(() => {
    onTimeChange && onTimeChange(timeLeft);
    if (timeLeft <= 0) {
      clearInterval(timeLeft);
      onEnd && onEnd();
    }
  }, [timeLeft, onEnd]);

  return <div>{dayjs().startOf('day').second(timeLeft).format('mm:ss')}</div>;
};

export default Countdown;
