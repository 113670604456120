import { Breadcrumbs, Button, Checkbox, Divider, InputField, Tooltip, Typography } from 'components';
import { ROUTES } from 'consts';
import * as yup from 'yup';
import styles from './TicketsAllocation.module.scss';
import { useMemo, useState } from 'react';
import { HelpCircleIcon, TicketSmallActive, TicketSmallDisabled } from 'assets/svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

interface AllocateForm {
  name: string;
  email: string;
  confirmEmail: string;
}

const TicketsAllocation = () => {

  const numberOfTickets = 5;
  const [allocatedTickets, setAllocatedTickets] = useState(2);
  // when should this page be accessible
  const [checked, setIsChecked] = useState(false);

  const schema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .min(1, 'Name must be at least 1 character long.')
          .max(100, 'Name cannot exceed 100 characters.')
          .required('Name is required'),
        email: yup
          .string()
          .trim()
          .required('Please enter your email address to continue.')
          .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
        confirmEmail: yup
          .string()
          .trim()
          .matches(EMAIL_REGEX, 'Please enter a valid email address.')
          .oneOf([yup.ref('email')], 'Please confirm your email')
          .required('Please confirm your email')
      }),
    [],
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<AllocateForm>({ mode: 'all', resolver: yupResolver(schema) });

  const onSubmit = async (data: AllocateForm) => {

  };

  return (
    <div>
      <div className="container">
        <Breadcrumbs title="My events" to={ROUTES.MY_EVENTS} />
      </div>
      <div className={styles.content}>
        <div className="m-b-48">
          <Typography variant="h4" weight="semibold" className="m-b-12">
            Who's coming?
          </Typography>
          <Typography variant="bodyS" color="gray300">
            To assign your tickets, please enter the name and email address of each additional attendee. They will claim
            the ticket via their email - so be sure to double check you've got it right!
          </Typography>
        </div>
        <div className={styles.contentAllocated}>
          <Typography variant="bodyS" color="gray300" weight="semibold" className="m-b-8">
            {allocatedTickets}/5 tickets assigned
          </Typography>
          <div className={styles.contentAllocatedWrap}>
            <div className={styles.contentAllocatedProgress} style={{ width: `${(allocatedTickets / numberOfTickets) * 100}%` }} />
          </div>
        </div>
        <form className={styles.contentAllocatedInputs} onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
                <InputField
                  label="Last Name"
                  placeholder="Enter attendee's name"
                  className="m-b-16"
                  onChange={onChange}
                  onBlur={onBlur}
                  invalid={isTouched && invalid}
                  errorText={error?.message}
                  value={value}
                />
              )}
            />
          </div>
          <div></div>
          <div>
            <Controller
              name="email"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
                <InputField
                  label="Attendee's Email"
                  placeholder="Enter attendee's email"
                  onChange={onChange}
                  onBlur={onBlur}
                  invalid={isTouched && invalid}
                  errorText={error?.message}
                  value={value}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="confirmEmail"
              rules={{ required: true }}
              control={control}
              render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid, isTouched } }) => (
                <InputField
                  label="Attendee's Email"
                  placeholder="Confirm Attendee's Email"
                  onChange={onChange}
                  onBlur={onBlur}
                  invalid={isTouched && invalid}
                  errorText={error?.message}
                  value={value}
                />
              )}
            />
          </div>
          <div>
            <Checkbox
              isChecked={checked}
              onChange={(e) => setIsChecked((e.target as HTMLInputElement).checked)}
            >
              <div className={styles.checkbox18Content}>
                <div>Attendee is under 18 years old</div>
                <Tooltip
                  className={styles.helpIcon18Tooltip}
                  content={
                    <Typography variant="bodyXs">
                      Some text
                    </Typography>
                  }
                  placement="top-start"
                  withArrow
                  offset={[0, 20]}
                >
                  <HelpCircleIcon className={styles.helpIcon18} />
                </Tooltip>
              </div>
            </Checkbox>
          </div>
        </form>

        <Button disabled={!isValid} variant="primary">Allocate Ticket</Button>

        <Divider className='m-b-32 m-t-32' />
        <Typography variant='bodyL' className='m-b-24'>Attendees</Typography>
        <div className={styles.attendeesList}>
          <div className={styles.attendeesItem}>
            <div className={styles.attendeesItemUser}>
              <Typography variant='bodyS'>NAME</Typography>
              <Typography variant='bodyS' color='gray300'>fff@ff.ff</Typography>
            </div>
            <div className={styles.resendAllocation}>
              <Button variant='link' size='XS'>Resend allocation</Button>
            </div>
            <div className={styles.ticket}>
              <TicketSmallActive />
            </div>
          </div>
          <div className={styles.attendeesItem}>
            <div className={styles.attendeesItemUser}>
              <Typography variant='bodyS'>NAME</Typography>
              <Typography variant='bodyS' color='gray300'>fff@ff.ff</Typography>
            </div>
            <div className={styles.resendAllocation}>
              <Button variant='link' size='XS'>Resend allocation</Button>
            </div>
            <div className={styles.ticket}>
              <TicketSmallDisabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketsAllocation;
