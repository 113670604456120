import { Typography } from 'components';
import InfoItem from './InfoItem/InfoItem';
// import LineupItem from './LineupItem/LineupItem';
import { IEvent } from 'types';
import styles from './EventInfo.module.scss';

interface EventHeaderProps {
  event: IEvent;
}

const EventInfo = ({ event }: EventHeaderProps) => (
  <section className={styles.info}>
    <div className={styles.infoTitle}>
      <div className="container">
        <Typography variant="h5" weight="bold" className={styles.infoTitleTypography}>
          Details
        </Typography>
      </div>
    </div>
    <div className="container">
      <div className={styles.infoDetails}>
        <div className={styles.infoRow}>
          <div
            className={styles.infoDescription}
            dangerouslySetInnerHTML={event.description ? { __html: event.description } : { __html: '' }}
          />
          <div>
            <div className="m-b-24">
              <InfoItem title="Venue" value={event.venue ?? ''} />
            </div>
            <div>
              <InfoItem title="Duration" value={event.duration} />
            </div>
          </div>
          {/* <div>
            <div>
              <LineupItem artists={event.artists} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </section>
);

export default EventInfo;
