import { LoaderIcon } from 'assets/svg';
import { ROUTES } from 'consts';
import { useNavigate } from 'react-router';
import { useGetUpcomingEventQuery, useVerifyAccessQuery } from 'store';
import styles from './CheckIn.module.scss';
import CheckInEvent from './components/CheckInEvent/CheckInEvent';

const CheckIn = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useVerifyAccessQuery();
  const { data: upcomingEvent } = useGetUpcomingEventQuery(undefined, { skip: !data?.hasAccess });

  if (isError) {
    navigate(ROUTES.LOGIN);
  }

  if (!upcomingEvent || isLoading) {
    return <LoaderIcon />;
  }

  return <div className={styles.container}>{upcomingEvent && <CheckInEvent event={upcomingEvent} />}</div>;
};

export default CheckIn;
