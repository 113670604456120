import { useState, useEffect, ReactNode, FC } from 'react';
import styles from './Drawer.module.scss';
import cn from 'classnames';
import { CloseIcon } from 'assets/svg';

interface DrawerProps {
  closable?: boolean,
  visible: boolean,
  placement?: 'left' | 'right' | 'top' | 'bottom',
  width?: string,  //width for left/right placement
  height?: string, //height fot top/bottom placement
  className?: string,
  contentClassName?: string,
  onClose: () => void,
  children: ReactNode,
}

const Drawer: FC<DrawerProps> = ({
  closable = true,
  visible = false,
  placement = 'right',
  width = 558,
  height = 558,
  onClose,
  children,
  className,
  contentClassName,
}) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const handleClose = () => {
    if (onClose) onClose();
    setIsVisible(false);
  };

  const drawerStyles = {
    width: placement === 'left' || placement === 'right' ? `${width}px` : '100%',
    height: placement === 'top' || placement === 'bottom' ? `${height}px` : '100%',
    transform: isVisible
      ? 'translate(0, 0)'
      : placement === 'right'
        ? 'translateX(100%)'
        : placement === 'left'
          ? 'translateX(-100%)'
          : placement === 'top'
            ? 'translateY(-100%)'
            : placement === 'bottom'
              ? 'translateY(100%)'
              : 'none',
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.overlay} style={{ opacity: isVisible ? 1 : 0, visibility: isVisible ? 'visible' : 'hidden' }} onClick={handleClose}></div>
      <div
        className={`${styles.drawer} ${styles[placement]}`}
        style={drawerStyles}
      >
        {closable && (
          <CloseIcon className={styles.closeIcon} onClick={handleClose} />
        )}
        <div className={cn(styles.content, contentClassName)}>{children}</div>
      </div>
    </div>
  );
};

export default Drawer;