export const ROUTES = {
  HOMEPAGE: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  EVENTS: '/events',
  EVENT: '/events/:id',
  STREAM: '/events/:id/stream',
  PURCHASE: '/purchase/:id',
  PURCHASE_TICKET: '/purchase-ticket/:id',
  PURCHASE_PROCESS: '/purchase/process/:id',
  PURCHASE_TICKET_PROCESS: '/purchase-ticket/process/:id',
  PURCHASE_TICKET_COMPLETED: '/purchase-ticket/complete/:id',
  PURCHASE_COMPLETED: '/purchase/complete/:id',
  VERIFY_ID: '/verify-id',
  TICKETS_ALLOCATION: '/tickets-allocation/:id',
  MY_EVENTS: '/my-events',
  AUTH_CALLBACK: '/auth/callback',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  PURCHASE_TICKET_PROCESS_SUMSUB: '/purchase-ticket/process/kyc/:id',
  SUMSUB: '/kyc',
  CHECK_IN: '/check-in',
  CUSTOMER_PORTAL: '/customer-portal',
  NOT_FOUND: '*',
};

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const GOOGLE_AUTH_URL = `${process.env.REACT_APP_PUBLIC_URL}/auth/google`;
export const FACEBOOK_AUTH_URL = `${process.env.REACT_APP_PUBLIC_URL}/auth/facebook`;
export const TIP_OPTIONS_AMOUNTS = [5, 10, 20];
export { COUNTRIES } from './countries';
