import { Footer, Header } from 'components';
import { Outlet } from 'react-router';
import styles from './Layout.module.scss';

const Layout = () => {
  return (
    <div className={styles.container}>
      <Header />
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
