import { useCallback, useState } from 'react';
import Slider, { type Settings } from 'react-slick';
import { CarouselItem } from 'components';
import { SlickArrowPrev, SlickArrowNext } from './components/SlickArrows/SlickArrows';
import { useGetAllEventsQuery, useGetMyEventsQuery } from 'store';
import styles from './Carousel.module.scss';

const Carousel = () => {
  const { data, isLoading } = useGetAllEventsQuery();
  const { data: myEvents, isLoading: isLoadingMyEvents } = useGetMyEventsQuery();

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings: Settings = {
    className: styles.slider,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (_, newIndex) => {
      setCurrentSlide(newIndex);
    },
    nextArrow: <SlickArrowPrev currentSlide={currentSlide} slideCount={data?.length ?? 0} />,
    prevArrow: <SlickArrowNext currentSlide={currentSlide} slideCount={data?.length ?? 0} />,
    infinite: data && data.length > 1,
    adaptiveHeight: true,
    initialSlide: 0,
  };

  const isEventBought = useCallback((id: string) => {
    if (!myEvents) {
      return false;
    }
    return Boolean(myEvents.find(e => e.id === id));
  }, [myEvents]);

  if (isLoading || isLoadingMyEvents) {
    return <div>Loading...</div>;
  }

  if (data && data.length === 1) {
    return (
      <div className={styles.slider}>
        <div className={styles.sliderItem}>
          <CarouselItem event={data[0]} currentSlide={currentSlide} index={0} eventsLength={data.length} bought={isEventBought(data[0].id)} />
        </div>
      </div>
    );
  }

  return (
    <Slider {...settings}>
      {data?.map((event, index) => (
        <CarouselItem
          key={event.id}
          event={event}
          currentSlide={currentSlide}
          index={index}
          eventsLength={data.length}
          bought={isEventBought(event.id)}
        />
      ))}
    </Slider>
  );
};

export default Carousel;
