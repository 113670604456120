import { ACCESS_TOKEN, ROUTES } from 'consts';
import { FC } from 'react';
import { Navigate } from 'react-router';

interface PublicRouteProps {
  children: JSX.Element;
  redirect?: boolean;
}

const PublicRoute: FC<PublicRouteProps> = ({ children, redirect = true }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  console.log('======redirect', redirect)
  if (accessToken && redirect) {
    // navigate to my events
    return <Navigate to={ROUTES.EVENTS} replace />;
  }
  return children;
};

export default PublicRoute;
