import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './SupportCreator.module.scss';
import { Button, Drawer, InputField, Typography } from 'components';
import { useTipMutation } from 'store';

import { loadStripe } from '@stripe/stripe-js';
import { notify, priceFormatter } from 'helpers';
import { StripePayment } from 'components';
import { TIP_OPTIONS_AMOUNTS } from 'consts';
import { ArrowBackIcon } from 'assets/svg';
import { IArtist } from 'types';


enum TipSteps {
  TIP,
  PAYMENT,
}

interface SupportCreatorProps {
  className?: string;
  artist: IArtist;
}
const SupportCreator: FC<SupportCreatorProps> = ({
  className,
  artist,
}) => {
  const [step, setStep] = useState(TipSteps.TIP);
  const [isDrawerOpened, openDrawer] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<any> | null>(null);
  const [tipAmount, setTipAmount] = useState("");
  const [selectedTipOption, setSelectedTipOption] = useState<number | null>(null);
  const [tip] = useTipMutation();

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!));
  }, []);

  useEffect(() => {
    if (!isDrawerOpened) {
      //clear all;
      setStep(TipSteps.TIP);
      setTipAmount("");
      setSelectedTipOption(null);
    }
  }, [isDrawerOpened]);

  useEffect(() => {
    if (tipAmount) {
      setSelectedTipOption(null);
    }
  }, [tipAmount])

  const handleGetIntent = async () => {
    try {
      const clientSecret = await tip({
        artistId: artist.id,
        amount: tipTotalAmount,
      });
      if (clientSecret.data) {
        setClientSecret(clientSecret.data.clientSecret);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const goToPayment = async () => {
    setStep(TipSteps.PAYMENT);
    if (stripePromise) {
      await handleGetIntent()
    }
  }

  const handlePay = async () => {
    notify.success("Tip successfully paid! Thank you for your support!");
    openDrawer(false);
  };

  const renderTipSelectOptions = useCallback((amounts: number[]) => {
    return amounts.map((amount) => (
      <div
        key={amount}
        className={cn(styles.tipAmount, {
          [styles.disabled]: tipAmount,
          [styles.selected]: selectedTipOption === amount
        })}
        onClick={() => setSelectedTipOption(amount)}
      >
        {priceFormatter(amount, false)}
      </div>
    ));
  }, [selectedTipOption, tipAmount]);

  const tipTotalAmount = useMemo(() => {
    if (selectedTipOption && !tipAmount) {
      return selectedTipOption;
    } else {
      return Number(tipAmount || 0);
    }
  }, [selectedTipOption, tipAmount]);

  const handleBack = () => {
    setStep(TipSteps.TIP);
  }

  return (
    <>
      <Button variant='secondary' size='S' className={className} iconLeft={artist.image ? <img className={styles.artistImg} src={artist.image} /> : undefined} onClick={() => openDrawer(true)}>Support creator</Button>
      <Drawer
        visible={isDrawerOpened}
        placement="right"
        onClose={() => openDrawer(false)}
      >
        <div className={styles.drawerContent}>
          <div className={styles.header}>
            <Typography variant='h5' color='white' weight='semibold' className='m-b-14'>Show Your Appreciation!</Typography>
            <Typography variant='bodyXs' color='gray200'>Support {artist.title} with a tip! Your contribution helps them keep creating amazing content.</Typography>
          </div>
          {step === TipSteps.TIP ? (
            <>
              <div className={styles.content}>
                <div className={cn(styles.tipAmountsWrapper, 'm-b-32')}>
                  {renderTipSelectOptions(TIP_OPTIONS_AMOUNTS)}
                </div>
                <InputField label="Custom tip" prefix="$" type='number' value={tipAmount} onChange={setTipAmount} placeholder='Enter custom amount' />
              </div>
              <div className={styles.summary}>
                <div className={styles.totalWrapper}>
                  <div className={styles.totalLabel}><Typography variant='h7' color='white'>Total:</Typography></div>
                  <div className={styles.totalAmount}><Typography variant='h7' color='white'>{priceFormatter(tipTotalAmount, false)}</Typography></div>
                </div>
                <Button variant='primary' size="L" width='100%' disabled={!tipTotalAmount} onClick={goToPayment}>Proceed to Payment</Button>
              </div>
            </>
          ) : null}
          {step === TipSteps.PAYMENT ? (
            <>
              <StripePayment
                clientSecret={clientSecret}
                stripe={stripePromise}
                onSubmit={handlePay}
                buttonsClassName={styles.paymentButtons}
                buttonText={`Pay Tip · ${priceFormatter(tipTotalAmount, false)}`}
                additionalButton={
                  <Button variant="secondary" size="M" onClick={handleBack} iconLeft={<ArrowBackIcon />}>
                    Back
                  </Button>
                }
              />
            </>
          ) : null}
        </div>
      </Drawer>
    </>
  );
};

export default SupportCreator;