import { FC } from 'react';
import { InputFieldProps } from './InputField';
import cn from 'classnames';
import OtpInput from 'react-otp-input';
import styles from './InputField.module.scss';
import { Typography } from 'components';

interface InputFieldOTPProps
  extends Pick<
    InputFieldProps,
    'value' | 'onChange' | 'label' | 'invalid' | 'disabled' | 'hint' | 'errorText' | 'className'
  > {
  numInputs: number;
  success?: boolean;
  inputClassName?: string;
}

const InputFieldOTP: FC<InputFieldOTPProps> = ({
  value,
  label,
  invalid,
  disabled,
  hint,
  success,
  errorText,
  inputClassName,
  className,
  numInputs,
  onChange,
}) => {
  return (
    <div className={cn(styles.inputFieldWrapper, className)}>
      {label && (
        <label className={cn(styles.label, 'm-b-8')}>
          <Typography variant="bodyS" weight="medium">
            {label}
          </Typography>
        </label>
      )}
      <div className={styles.inputOtpWrapper}>
        <OtpInput
          value={value}
          inputType="tel" // default to just numbers
          onChange={(value) => (onChange ? onChange(value) : undefined)}
          numInputs={numInputs}
          renderSeparator={<span className={styles.otpSeparator} />}
          renderInput={(props) => (
            <input
              {...props}
              disabled={disabled}
              className={cn(
                styles.input,
                {
                  [styles.invalid]: invalid,
                  [styles.success]: success,
                },
                inputClassName,
              )}
            />
          )}
        />
      </div>
      {hint && !errorText && (
        <div className={styles.hint}>
          <Typography variant="bodyS">{hint}</Typography>
        </div>
      )}
      {invalid && errorText && (
        <div className={styles.errorText}>
          <Typography variant="bodyS">{errorText}</Typography>
        </div>
      )}
    </div>
  );
};

export default InputFieldOTP;
