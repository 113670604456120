import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import styles from "./Select.module.scss";
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import cn from 'classnames';
import { InputField, Typography } from "components";
import { usePrevious, useUpdateEffect } from "helpers";
import { CheckIcon, ChevronDownIcon, SearchIcon } from "assets/svg";

const scrollEvent = new Event('scroll');

interface ISelectOption {
  label: string;
  value?: string | number;
  disabled?: boolean;
}

type OptionType = ISelectOption | string | number;

export interface SelectProps {
  placeholder?: string;
  label?: string | ReactNode;
  hint?: string | ReactNode;
  errorText?: string | ReactNode;
  invalid?: boolean;
  value?: number | string | Array<number | string>
  isMulti?: boolean;
  options?: Array<OptionType>;
  emptyOptionsText?: string;
  noSearchResultsText?: string;
  disabled?: boolean;
  className?: string;
  withSearch?: boolean;
  readOnly?: boolean;
  dropdownHeight?: number;
  dropdownPlacement?: Placement;
  onChange?: (value: any) => void;
  onClose?: (value?: any) => void;
  onChangeDropdownState?: (value: boolean) => void;
}

const Select: FC<SelectProps> = ({
  placeholder,
  label,
  hint,
  errorText,
  invalid = false,
  value = '',
  isMulti,
  options = [],
  emptyOptionsText = 'Options list is empty...',
  withSearch = false,
  noSearchResultsText = 'No results...',
  readOnly = false,
  disabled = false,
  className,
  dropdownHeight = 300,
  dropdownPlacement = 'bottom',
  onChange,
  onClose,
  onChangeDropdownState,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [rootElement, setRootElement] = useState<Element | null>(null);
  const popperElementRef = useRef<any>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [lastKey, setLastKey] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: dropdownPlacement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const setPopperElementBoth = (element: any) => {
    setPopperElement(element);
    popperElementRef.current = element;
  };
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const [isOpen, setOpen] = useState(false);
  const previousOpen = usePrevious(isOpen);

  const dropdownStyles = useMemo(() => {
    return {
      ...popperStyles.popper,
      maxHeight: dropdownHeight + 'px',
      width: referenceElement !== null ? referenceElement.clientWidth + 2 : 'auto',
    };
  }, [popperStyles, isOpen]);

  useEffect(() => {
    setRootElement(document.querySelector('#root'));
  }, []);

  const scrollToOption = (optionRef: any) => {
    const optionRect = optionRef?.getBoundingClientRect();
    const dropdownRect = popperElementRef?.current?.getBoundingClientRect();
    if (optionRect && dropdownRect && popperElementRef && popperElementRef.current) {
      const optionOffsetTop = optionRect.top - dropdownRect.top + popperElementRef.current.scrollTop;
      const optionHeight = optionRect.height;
      const dropdownHeight = dropdownRect.height;
      const scrollTop = optionOffsetTop - (dropdownHeight / 2) + (optionHeight / 2);
      popperElementRef.current.scrollTop = scrollTop;
    }
  };

  const scrollOptionIfNeeded = (optionRef: any) => {
    const dropdown = popperElementRef.current;
    const dropdownRect = dropdown.getBoundingClientRect();
    const elementRect = optionRef.getBoundingClientRect();

    if (elementRect.top < dropdownRect.top) {
      dropdown.scrollTop -= (dropdownRect.top - elementRect.top);
    } else if (elementRect.bottom > dropdownRect.bottom) {
      dropdown.scrollTop += (elementRect.bottom - dropdownRect.bottom);
    }
  };

  useEffect(() => {
    if (previousOpen && !isOpen) {
      onClose && onClose();
      setSearchValue('');
      setHighlightedIndex(null);
    }
    if (!previousOpen && isOpen) {
      if (isMulti && Array.isArray(value)) {
        const selectedOptions = value.map(v => options.find(e => getValue(e) === v));
        const selectedOptionsIndexes = selectedOptions.map(s => getOptionIndex(s as OptionType));
        selectedOptionsIndexes.sort((a, b) => a > b ? 1 : -1);
        if (selectedOptionsIndexes.length) {
          scrollToOption(optionRefs.current[selectedOptionsIndexes[0]]);
        }
      } else {
        const selectedOption = options.find(e => getValue(e) === value);
        const optionIndex = selectedOption ? getOptionIndex(selectedOption) : 0;
        scrollToOption(optionRefs.current[optionIndex]);
      }
    }
    if (onChangeDropdownState !== undefined) {
      onChangeDropdownState(isOpen);
    }
  }, [isOpen, onClose, previousOpen]);

  useEffect(() => {
    if (highlightedIndex !== null && optionRefs.current[highlightedIndex]) {
      scrollOptionIfNeeded(optionRefs.current[highlightedIndex]);
    }
  }, [highlightedIndex]);

  const open = () => {
    if (!disabled && !readOnly) {
      setOpen(true);
    }
  };

  const toggleOpenState = () => {
    if (!disabled) {
      setOpen(!isOpen);
    }
  };

  const filteredOptions = useMemo(() => options.filter((option) => {
    const formatedSearchQuery = searchValue.trim().toLowerCase();
    const formatedLabel = (typeof option === "object" ? option.label : option).toString().trim().toLowerCase();

    return formatedLabel.includes(formatedSearchQuery);
  }), [options, searchValue]);

  const getOptionIndex = (option: OptionType) => {
    return options.findIndex(e => getValue(e) === getValue(option));
  };

  const isSelected = (option: OptionType) => {
    const optionValue = getValue(option);
    if (!value) return false;
    if (isMulti && Array.isArray(value)) {
      return typeof value === 'number' ? value === optionValue : value.includes(optionValue);
    } else {
      return value === optionValue;
    }
  };

  const isOptionDisabled = (option: OptionType) => {
    return typeof option === 'object' && option.disabled;
  };


  const getValue = (option: OptionType) => {
    return typeof option === 'object' ? (option.value || option.label) : option;
  };

  const removeValue = (item: number | string) => {
    if (Array.isArray(value)) {
      const newValue = value.filter(v => v !== item);
      if (onChange !== undefined) {
        onChange(newValue);
      }
      window.dispatchEvent(scrollEvent);
    }
  };

  const onLabelClick = (event: any, option: OptionType) => {
    event.stopPropagation();
    const selectedValue = getValue(option);
    if (isMulti) {
      let newValue;
      if (Array.isArray(value) && value.find(i => i === selectedValue)) {
        newValue = value.filter(i => i !== selectedValue);
      } else if (Array.isArray(value)) {
        newValue = [...value, selectedValue];
      } else {
        newValue = [selectedValue];
      }
      if (onChange !== undefined) {
        onChange(newValue);
      }
      window.dispatchEvent(scrollEvent);
    } else {
      if (onChange !== undefined) {
        onChange(selectedValue);
      }
      setOpen(false);
    }
  };

  const isValueEmpty = useMemo(() => {
    const isEmpty = Array.isArray(value) ? !value.length : value === undefined || value === '';
    return isEmpty || options.every(el => {
      const elValue = typeof el === 'object' ? el.value || el.label : el;
      const isOptionSelected = Array.isArray(value) ? value.includes(elValue) : elValue === value;
      return !isOptionSelected;
    });
  }, [value, options]);

  const findLabelByValue = (value?: number | string) => {
    const opt = options.find(option => {
      const curValue = getValue(option);
      return curValue === value;
    });
    return typeof opt === 'object' ? opt.label : opt;
  };

  const getSingleValueLabel = useMemo(() => {
    return Array.isArray(value) ? value : findLabelByValue(value);
  }, [value, options]);

  useUpdateEffect(() => {
    const handleClickOutside = (event: any) => {
      const canClose = referenceElement !== null
        && popperElement !== null
        && !(referenceElement.contains(event.target as Node)
          || popperElement.contains(event.target as Node));
      if (canClose) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [referenceElement, popperElement]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // check is multi
    if (!isOpen) {
      if (!isMulti && event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey && /^[a-zA-Z0-9]$/.test(event.key)) {
        event.preventDefault();
        const key = event.key.toLowerCase();
        const matches = options.filter(option => {
          const optionLabel = typeof option === 'object' ? option.label : option;
          return optionLabel.toString().toLowerCase().startsWith(key);
        });
        if (matches.length) {
          onLabelClick(event, options[getOptionIndex(matches[0])]);
        }
      } else if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        event.preventDefault();
        if (isMulti && Array.isArray(value)) {
          const selectedOptions = value.map(v => options.find(e => getValue(e) === v));
          const selectedOptionsIndexes = selectedOptions.map(s => getOptionIndex(s as OptionType));
          selectedOptionsIndexes.sort((a, b) => a > b ? 1 : -1);
          setHighlightedIndex(selectedOptionsIndexes[0] || 0);
        } else {
          const selectedOption = options.find(e => getValue(e) === value);
          const optionIndex = selectedOption ? getOptionIndex(selectedOption) : 0;
          setHighlightedIndex(optionIndex);
        }
        open();
      }
    } else if (!withSearch) {
      if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey && /^[a-zA-Z0-9]$/.test(event.key)) {
        event.preventDefault();
        const key = event.key.toLowerCase();
        if (key === lastKey) {
          const currentOption = options[highlightedIndex || 0];
          const currentSearchResultsIndex = searchResults.findIndex((e: any) => getValue(e) === getValue(currentOption));
          const newIndex = ((currentSearchResultsIndex || 0) + 1) % searchResults.length;
          setHighlightedIndex(getOptionIndex(searchResults[newIndex]));
        } else {
          const matches = options.filter(option => {
            const optionLabel = typeof option === 'object' ? option.label : option;
            return optionLabel.toString().toLowerCase().startsWith(key);
          });
          setSearchResults(matches);
          setHighlightedIndex(getOptionIndex(matches[0]));
        }
        setLastKey(key);
      } else {
        const getCurrentPossibleHighlighedIndex = () => {
          if (isMulti && Array.isArray(value)) {
            const selectedOptions = value.map(v => options.find(e => getValue(e) === v));
            const selectedOptionsIndexes = selectedOptions.map(s => getOptionIndex(s as OptionType));
            selectedOptionsIndexes.sort((a, b) => a > b ? 1 : -1);
            return selectedOptionsIndexes[0] || 0;
          } else {
            const selectedOption = options.find(e => getValue(e) === value);
            const optionIndex = selectedOption ? getOptionIndex(selectedOption) : 0;
            return optionIndex;
          }
        };
        const currentPossibleHighlighedIndex = getCurrentPossibleHighlighedIndex();
        switch (event.key) {
          case 'ArrowDown':
            setHighlightedIndex(prevIndex => {
              if (prevIndex === null) {
                if (currentPossibleHighlighedIndex > 0) {
                  return (currentPossibleHighlighedIndex + 1) % options.length;
                }
                return 0;
              }
              return (prevIndex + 1) % options.length;
            });
            event.preventDefault();
            break;
          case 'ArrowUp':
            setHighlightedIndex(prevIndex => {
              if (prevIndex === null) {
                if (currentPossibleHighlighedIndex > 0) {
                  return (currentPossibleHighlighedIndex - 1 + options.length) % options.length;
                }
                return options.length - 1;
              }
              return (prevIndex - 1 + options.length) % options.length;
            });
            event.preventDefault();
            break;
          case 'Enter':
            if (highlightedIndex !== null) {
              onLabelClick(event, options[highlightedIndex]);
            }
            break;
          case 'Escape':
          case 'Tab':
            setOpen(false);
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className={cn(styles.selectWrapper, className)}>
      {label && (
        <label className={cn(styles.label, 'm-b-8')}>
          <Typography variant="bodyS" weight="medium">{label}</Typography>
        </label>
      )}
      <div
        ref={setReferenceElement}
        className={cn(styles.select, {
          [styles.invalid]: invalid,
          [styles.disabled]: disabled,
          [styles.readOnly]: readOnly,
        })}
        onClick={open}
        tabIndex={disabled ? -1 : 0}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.selectContent}>
          {isValueEmpty && <div className={styles.placeholder}>{placeholder}</div>}
          {value && !isMulti && <div className={styles.value}>
            <div className={cn(styles.textWrapper)}><div>{getSingleValueLabel}</div></div>
          </div>}
          {value && isMulti && Array.isArray(value) && value.map(i => (
            <div className={styles.valueItem} key={`selected-value-${findLabelByValue(i)}`}>
              <span>{findLabelByValue(i)}</span>
              <div onClick={() => removeValue(i)}>R</div>
              {/* <CloseIcon onClick={() => removeValue(i)} /> */}
            </div>
          ),
          )}
        </div>
        <div className={cn(styles['right-icon'], { 'open': isOpen })} onClick={(e) => {
          toggleOpenState(); e.stopPropagation();
        }}>
          <ChevronDownIcon />
          {/* {isOpen ? <ArrowTopIcon /> : <ArrowDownIcon />} */}
        </div>
        {rootElement !== null && isOpen && (ReactDOM.createPortal(
          <div
            ref={setPopperElementBoth}
            style={dropdownStyles}
            {...attributes.popper}
            className={cn(styles.selectSource, { multi: isMulti })}
          >
            {withSearch ? (
              <InputField
                value={searchValue}
                onChange={setSearchValue}
                className={styles.selectSearch}
                placeholder="Search..."
                prefix={<SearchIcon className={styles.selectSearchIcon} />}
              />
            ) : null}
            {filteredOptions.length > 0 && filteredOptions.map((option, optionIndex) => (
              <div
                key={`select-option-${optionIndex}-${typeof option === 'object' ? option.label : option}`}
                className={cn(styles.option, {
                  [styles.selected]: isSelected(option),
                  [styles.disabled]: isOptionDisabled(option),
                  [styles.highligted]: optionIndex === highlightedIndex
                })}
                ref={el => optionRefs.current[optionIndex] = el}
                onClick={(e) => onLabelClick(e, option)}
              >
                <div className={styles.optionContent}>
                  <div className={styles.label}>
                    {isMulti && (
                      <div className={cn(styles['checkbox-icon'])}>
                        K
                        {/* <CheckIcon /> */}
                      </div>
                    )}
                    <span>{typeof option === 'object' ? option.label : option}</span>
                    {isSelected(option) && !isMulti && <div className={styles.rightSelectedIcon}>
                      <CheckIcon />
                    </div>}
                  </div>
                </div>
              </div>
            ))}
            {options.length === 0 && (
              <div className={styles.emptyText}>{emptyOptionsText}</div>
            )}
            {options.length > 0 && filteredOptions.length === 0 && (
              <div className={styles.emptyText}>{noSearchResultsText}</div>
            )}
          </div>,
          rootElement,
        ))}
      </div>
      {hint && !errorText && <div className={styles.hint}><Typography variant="bodyS">{hint}</Typography></div>}
      {invalid && errorText && <div className={styles.errorText}><Typography variant="bodyS">{errorText}</Typography></div>}
    </div>
  );
};

export default Select;
