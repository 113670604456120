import { Typography } from 'components';
import styles from './TermsOfService.module.scss';

const TermsOfService = () => (
  <div className={styles.terms}>
    <Typography variant="h1" align="center">Terms and Conditions</Typography>
    <div className={styles.termsDate}>Effective Date: 5th February 2025</div>
      <Typography variant="h5" className={styles.termsTitle}>1. Introduction</Typography>
      <Typography variant="bodyMd" className={styles.termsBody}>These Terms and Conditions ("Terms") govern your use of the services
        provided by ENTX Ltd ("we", "us", or "our") through our Software as a Service (SaaS) platform. By using or accessing our services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, do not use our services.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>2. Services Provided</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>ENTX Ltd offers live entertainment functionalities, including streaming
        services and ticketing. The services are provided on a pay-per-use and may be subject to additional terms and conditions.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>3. User Accounts</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>In order to use our services, you must create an account. You agree to
        provide accurate, up-to-date information during registration and keep your account information secure. You are responsible for all activities that occur under your account.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>4. Subscription and Payment</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>Our services are offered on a subscription basis. By subscribing to our service, you agree to pay the applicable subscription fees, as outlined on our website. Subscription fees are non-refundable except as required by law or in accordance with our refund policy.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>5. Restrictions</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>
        <p className={styles.termsListTitle}>You agree not to:</p>
        <ul className={styles.list}>
            <li>Use the service for any unlawful purpose.</li>
            <li>Attempt to gain unauthorized access to our systems or services.</li>
            <li>Reverse-engineer, decompile, or disassemble any software or content provided by us.</li>
        </ul>
    </Typography>
      <Typography variant="h5" className={styles.termsTitle}>6. Termination</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>We reserve the right to suspend or terminate your account if you violate these
        Terms. You may also terminate your account at any time by following the termination procedure outlined in the service.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>7. Limitations of Liability</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>We will not be held liable for any damages, losses, or expenses arising from the use or inability to use our services, including any indirect or consequential damages, to the fullest extent permitted by applicable law.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>8. Intellectual Property</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>All intellectual property rights in our services, software, and content are owned by ENTX Ltd. You may not use, copy, or modify any of our intellectual property except as expressly permitted by these Terms.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>9. Privacy</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>Your use of our services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your data.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>10. Changes to the Terms</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>We reserve the right to update these Terms at any time. Any changes will be posted on our website with an updated effective date. Continued use of the service after changes are posted constitutes your acceptance of the updated Terms.</Typography>
      <Typography variant="h5" className={styles.termsTitle}>11. Governing Law</Typography>
    <Typography variant="bodyMd" className={styles.termsBody}>These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes shall be subject to the exclusive jurisdiction of the courts of England and Wales.</Typography>
    <Typography variant="h5" className={styles.termsTitle}>12. Contact Information</Typography>
      <Typography variant="bodyMd" className={styles.termsBody}>If you have any questions regarding these Terms, please contact us at <a href="mailto:support@entx.io" className={styles.mailto}>support@entx.io</a>.</Typography>

  </div>
);

export default TermsOfService;
