import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'helpers';
import { IReqArgs } from 'types';
import { ITicket } from 'types/ticket';

export const ticketApi = createApi({
  reducerPath: 'ticketApi',
  baseQuery: getBaseQuery('/offline-tickets'),
  tagTypes: ['Ticket'],
  endpoints: (builder) => ({
    getTicketById: builder.query<ITicket, IReqArgs<{ id: string }>>({
      query: ({ id, config }) => ({
        url: `/${id}`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
      providesTags: (result, error, req) => [{ type: 'Ticket', id: req.id }],
    }),
    reserveTicket: builder.mutation<{ clientSecret: string }, IReqArgs<{ eventId: string, quantity: number }>>({
      query: ({ eventId, quantity, config }) => ({
        url: '/reserve',
        method: 'POST',
        body: { eventId, quantity },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    purchaseTicket: builder.mutation<{ clientSecret: string }, IReqArgs<{ eventId: string, quantity: number }>>({
      query: ({ eventId, quantity, config }) => ({
        url: '/purchase',
        method: 'POST',
        body: { eventId, quantity },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    verify: builder.mutation<any, IReqArgs<{ id: string; entryStatus: string }>>({
      query: ({ id, entryStatus, config }) => ({
        url: '/verify',
        method: 'PATCH',
        body: {
          id,
          entryStatus,
        },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
      invalidatesTags: ['Ticket'],
    }),
  }),
});

export const { useGetTicketByIdQuery, useVerifyMutation, usePurchaseTicketMutation, useReserveTicketMutation } = ticketApi;

export default ticketApi.reducer;
