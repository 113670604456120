import { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Typography.module.scss';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'bodyXl'
  | 'bodyL'
  | 'bodyMd'
  | 'bodyS'
  | 'bodyXs';

export type TypographyColor =
  | 'white'
  | 'black'
  | 'error'
  | 'primary25'
  | 'primary50'
  | 'primary100'
  | 'primary200'
  | 'primary300'
  | 'primary400'
  | 'primary500'
  | 'primary600'
  | 'primary700'
  | 'primary800'
  | 'primary850'
  | 'primary900'
  | 'gray25'
  | 'gray50'
  | 'gray100'
  | 'gray200'
  | 'gray300'
  | 'gray400'
  | 'gray500'
  | 'gray600'
  | 'gray700'
  | 'gray800'
  | 'gray850'
  | 'gray900'
  | 'gray950';

export interface TypographyProps {
  variant: TypographyVariant;
  noWrap?: boolean;
  color?: TypographyColor;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  weight?: 'regular' | 'medium' | 'semibold' | 'bold';
  className?: string;
  children: ReactNode;
}

const Typography: FC<TypographyProps> = ({
  variant,
  noWrap = false,
  align,
  color,
  weight = 'regular',
  className,
  children,
}) => {
  const Component: keyof JSX.IntrinsicElements = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)
    ? (variant as keyof JSX.IntrinsicElements)
    : 'p';
  return (
    <Component
      className={cn(
        styles.typography,
        {
          [variant ? styles[variant] : '']: variant,
          [weight ? styles[weight] : '']: weight,
          [color ? styles[color] : '']: color,
          [styles.noWrap]: noWrap,
          [align ? styles[`align-${align}`] : '']: align,
        },
        className,
      )}>
      {children}
    </Component>
  );
};

export default Typography;
