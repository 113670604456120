import { Typography } from 'components';
import { formatDateLong, formatTime } from 'helpers';
import { IEvent } from 'types';
import styles from './EventInfo.module.scss';

interface EventInfoProps {
  event: IEvent;
}

const EventInfo = ({ event }: EventInfoProps) => (
  <section className={styles.info}>
    <img src={event.heroBanner} className={styles.infoImage} width={676} height={473} alt={event.title} />
    <Typography variant="h3" weight="semibold" className="m-b-8">
      {event.title}
    </Typography>
    <Typography variant="bodyS" className={styles.infoDate}>
      {formatDateLong(event.startDate)}
      <span className={styles.infoDateDivider} />
      {formatTime(event.startDate).toLowerCase()}
    </Typography>
  </section>
);

export default EventInfo;
