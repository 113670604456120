import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'consts';

type RouteParams = Record<string, string>;

export const useAppNavigate = () => {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (routeKey: keyof typeof ROUTES, params?: RouteParams, query?: string, state?: any) => {
      let route = ROUTES[routeKey];
      if (params) {
        Object.keys(params).forEach((key) => {
          route = route.replace(`:${key}`, params[key]);
        });
      }
      if (query) {
        route += `?${query}`;
      }
      navigate(route, { state });
    },
    [navigate],
  );

  return { navigateTo };
};
