import { Button, Header, Typography } from 'components';
import styles from './VerifyID.module.scss';
import { useEffect } from 'react';
import { notify } from 'helpers';
import { useGetAccountQuery } from 'store';

const VerifyID = () => {
  const { data: accountData } = useGetAccountQuery();

  const onResendEmail = () => {
    notify.success('The email has been resent.');
  };

  useEffect(() => {
    notify.info('We noticed you haven’t verified your ID yet. Please complete this step to proceed.');
  }, []);

  return (
    <div className={styles.vefify}>
      <Header hideProfile />
      <main className={styles.vefifyContent}>
        <Typography variant="h4" weight="semibold" className="m-b-12">
          Please check your email
        </Typography>
        <Typography variant="bodyS" color="gray300" className="m-b-32">
          We’ve sent an email to <strong>{accountData?.email}</strong> with the ID verification link. Please complete
          this step to secure your ticket and move forward with your purchase.
        </Typography>
        <Typography variant="bodyS" color="gray300">
          Didn’t receive the email?{' '}
          <Button variant="link" onClick={onResendEmail}>
            Resend Email
          </Button>
        </Typography>
      </main>
    </div>
  );
};

export default VerifyID;
