export enum StreamStatus {
  CREATED = 'CREATED',
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  TRANSFER_ERROR = 'TRANSFER_ERROR',
}

export enum StreamReactionType {
  LIKE = 'like',
  DISLIKE = 'dislike',
}
