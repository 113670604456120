import { LogoIcon } from 'assets/svg';
import { Button } from 'components';
import { useState } from 'react';
import { Link } from 'react-router';
import { IEvent } from 'types';
import EventInfo from '../EventInfo/EventInfo';
import QRCodeReader from '../QRCodeReader/QRCodeReader';
import styles from './CheckInEvent.module.scss';

interface CheckInEventProps {
  event: IEvent;
}

const CheckInEvent = ({ event }: CheckInEventProps) => {
  const [isScan, setIsScan] = useState(false);

  const handleStartScan = () => {
    setIsScan(true);
  };

  return (
    <div className={styles.container}>
      {isScan ? (
        <QRCodeReader eventId={event.id} />
      ) : (
        <div className={styles.wrapper}>
          <Link to="/" className={styles.logo} onClick={() => window.scrollTo(0, 0)}>
            <LogoIcon />
          </Link>

          <EventInfo event={event} />

          <Button variant="primary" width="100%" type="button" size="M" className="m-b-32" onClick={handleStartScan}>
            Start Scanning
          </Button>
        </div>
      )}
    </div>
  );
};

export default CheckInEvent;
