import { Navigate, useNavigate, useParams } from 'react-router';
import { useGetOneEventQuery, useLazyCheckEventStatusQuery, useLazyGetOneEventQuery, useLazyGetStreamStatisticsQuery } from 'store';
import { ROUTES } from 'consts';
import { detect } from "detect-browser";
import EventInfo from '../EventDetails/components/EventInfo/EventInfo';
import { Player, Typography } from 'components';
import StreamHeader from './components/StreamHeader/StreamHeader';
import { useCallback, useEffect, useMemo } from 'react';
import { StreamStatus } from 'enums';
import styles from './EventStream.module.scss';

const EventStream = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [getEvent] = useLazyGetOneEventQuery();
  const { data: event, isLoading } = useGetOneEventQuery({ id: id! });
  const [getStatusData, { isLoading: isStatusLoading }] = useLazyCheckEventStatusQuery();
  const [getStreamStatistics, { data: streamStatistics }] = useLazyGetStreamStatisticsQuery();
  const browser = detect()

  const checkStatus = useCallback(async () => {
    try {
      const response = await getStatusData({ id: id! });
      if (response.data?.status !== "completed") {
        navigate(ROUTES.EVENTS);
      }
    } catch (e) {
      navigate(ROUTES.EVENTS);
    }
  }, [])

  useEffect(() => {
    checkStatus();
  }, [])

  const isStreamInitiallyFinished = useMemo(() => {
    return event?.stream?.status === StreamStatus.FINISHED;
  }, [event])


  const getStreamStats = useCallback(async () => {
    if (event?.streamId) {
      await getStreamStatistics({ id: event.streamId });
    }
  }, [event]);

  useEffect(() => {
    getStreamStats();
  }, [event]);

  useEffect(() => {
    const interval = setInterval(async () => {
      await getStreamStats();
      if (!event?.stream?.hlsManifestUrl) {
        getEvent({ id: id! })
      }
    }, 30000); //every 30 sec check if stream has finished
    return () => clearInterval(interval);
  }, [event])

  if (!event && !isLoading && !isStatusLoading) {
    return <Navigate to={ROUTES.EVENTS} />;
  }

  return (
      <div>
        {!isLoading && !isStatusLoading && event && streamStatistics && (
            <>
              {(() => {
                const { status, connected } = streamStatistics;
                const isSafari = browser?.name === 'safari';

                if (
                    (!isStreamInitiallyFinished && status !== StreamStatus.RUNNING) ||
                    (isStreamInitiallyFinished && status === StreamStatus.FINISHED && isSafari) ||
                    (!isStreamInitiallyFinished && status === StreamStatus.RUNNING && !connected)
                ) {
                  return (
                      <div className={styles.playerPlaceholderWrapper}>
                        <div className={styles.playerContent}>
                          {status === StreamStatus.FINISHED && (
                              <Typography variant="bodyMd">
                                Stream has finished. Thanks for watching
                              </Typography>
                          )}
                          {[StreamStatus.CREATED, StreamStatus.QUEUED].includes(status) || !connected ? (
                              <Typography variant="bodyMd">Please wait for starting of stream</Typography>
                          ) : null}
                        </div>
                      </div>
                  );
                }

                return null;
              })()}

              {(isStreamInitiallyFinished && browser?.name !== 'safari') ||
              (streamStatistics.status === StreamStatus.RUNNING && streamStatistics.connected) ? (
                  <Player event={event} />
              ) : null}

              <StreamHeader event={event} />
              <EventInfo event={event} />
            </>
        )}
      </div>
  );

};

export default EventStream;
