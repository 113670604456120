import { FC, ReactNode } from "react";
import cn from 'classnames';
import styles from './InputField.module.scss';
import { Typography } from "components";
import { AlertCircleIcon } from "assets/svg";

export interface InputFieldProps {
  placeholder?: string;
  addonBefore?: string | ReactNode;
  type?: 'text' | 'email' | 'number';
  addonAfter?: string | ReactNode;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
  label?: string | ReactNode;
  hint?: string | ReactNode;
  errorText?: string | ReactNode;
  invalid?: boolean;
  value?: string;
  disabled?: boolean;
  className?: string;
  isReturnEvent?: boolean;
  onChange?: (value: string | any) => void;
  onBlur?: (value: string | any) => void;
  onKeyDown?: (value: string | any) => void;
  onFocus?: (value: string | any) => void;
}

const InputField: FC<InputFieldProps> = ({
  placeholder,
  addonBefore,
  addonAfter,
  prefix,
  suffix,
  label,
  type = "text",
  hint,
  invalid,
  errorText,
  disabled,
  className,
  value = "",
  isReturnEvent,
  onChange,
  onBlur,
  onFocus,
  onKeyDown
}) => {
  return (
    <div className={cn(styles.inputFieldWrapper, className)}>
      {label && (
        <label className={cn(styles.label, 'm-b-8')}>
          <Typography variant="bodyS" weight="medium">{label}</Typography>
        </label>
      )}
      {/* TODO: add onclick focus to ref of input */}
      <div className={cn(styles.inputGroup, {
        [styles.invalid]: invalid,
        [styles.disabled]: disabled
      })}>
        {addonBefore && <div className={styles.addonBefore}>{addonBefore}</div>}
        <div className={styles.inputContainer}>
          {prefix && <span className={styles.prefix}>{prefix}</span>}
          <input
            type={type}
            value={value}
            placeholder={placeholder}
            className={cn(styles.input)}
            disabled={disabled}
            onChange={e => onChange ? onChange(isReturnEvent ? e : e.target.value) : undefined}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
          />
          {suffix && !invalid && <span className={styles.suffix}>{suffix}</span>}
          {invalid && <span className={styles.suffix}><AlertCircleIcon className={styles.invalidCircleIcon} /></span>}
        </div>
        {addonAfter && <div className={styles.addonAfter}>{addonAfter}</div>}
      </div>
      {hint && !errorText && <div className={styles.hint}><Typography variant="bodyS">{hint}</Typography></div>}
      {invalid && errorText && <div className={styles.errorText}><Typography variant="bodyS">{errorText}</Typography></div>}
    </div>
  );
};

export default InputField;
