import { FC, ReactElement, ReactNode, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './Tooltip.module.scss';
import cn from 'classnames';
import { usePopper } from 'react-popper';

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

interface Props {
  children: ReactNode;
  content: string | ReactElement;
  className?: string;
  placement?: Placement;
  disabled?: boolean;
  offset?: [number, number];
  withArrow?: boolean;
  inline?: boolean;
  flex?: boolean;
}

const Tooltip: FC<Props> = ({
  children,
  content,
  className,
  offset = [0, 8],
  placement = 'auto',
  disabled = false,
  withArrow = false,
  inline = false,
  flex = false,
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [rootElement, setRootElement] = useState<Element | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 5, // Adjust padding between arrow and tooltip
        },
      },
    ],
  });
  const showTooltip = () => {
    if (!disabled && !isShow) {
      setIsShow(true);
    }
  };
  const hideTooltip = () => {
    setIsShow(false);
  };

  useEffect(() => {
    setRootElement(document.querySelector('#root'));
  }, []);

  return (
    <div className={cn(styles.tooltipWrapper, className, { 'd-inline-block': inline })}>
      <div
        ref={setReferenceElement}
        className={cn(styles.tooltipTrigger, { 'd-inline-block': inline, flex: flex })}
        onMouseOverCapture={showTooltip}
        onMouseLeave={hideTooltip}>
        {children}
      </div>
      {rootElement !== null &&
        isShow &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
            className={cn(styles.tooltipContent)}>
            {content}
            {withArrow && <div ref={setArrowElement} style={popperStyles.arrow} className={styles.tooltipArrow}></div>}
          </div>,
          rootElement,
        )}
    </div>
  );
};

export default Tooltip;
