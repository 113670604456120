import Typography from 'components/Typography/Typography';
import HeaderDropdown from '../HeaderDropdown/HeaderDropdown';
import styles from './UserProfile.module.scss';
import { useGetAccountQuery } from 'store';
import { LoaderIcon } from 'assets/svg';

const UserProfile = () => {
  const { data: accountData, isLoading: isGetAccountLoading } = useGetAccountQuery();
  const firstLetters = `${accountData?.firstName[0] || ''}${accountData?.lastName[0] || ''}`;

  return (
    <div className={styles.wrap}>
      <HeaderDropdown>
        <div className={styles.avatar}>
          {isGetAccountLoading ? (
            <LoaderIcon />
          ) : accountData?.socialPictureUrl ? (
            <img src={accountData?.socialPictureUrl} alt={firstLetters} />
          ) : (
            <Typography variant="bodyMd" weight="medium">
              {firstLetters}
            </Typography>
          )}
        </div>
      </HeaderDropdown>
    </div>
  );
};

export default UserProfile;
