import { Typography } from 'components';
import styles from './MeetENTX.module.scss';

const ITEMS = [
  {
    title: 'Fairer',
    description: 'Greater share of earnings from live events goes to creators and venues.',
  },
  {
    title: 'Future-facing',
    description:
      'Thoughtful application of technology to preserve and protect what the live entertainment industry stands for.',
  },
  {
    title: '...but with old fashioned values',
    description: 'Honouring the history of live entertainment - from grassroots to the global stage.',
  },
  {
    title: 'New opportunities',
    description: 'Building new ways for fans and creators to connect and show support',
  },
  {
    title: 'More connected than ever before',
    description: (
      <>
        Built for live entertainment lovers <i>by</i> live entertainment lovers
      </>
    ),
  },
];

const MeetENTX = () => (
  <div className={styles.meetEntx}>
    <div className="container">
      <div className={styles.meetEntxRow}>
        <div className={styles.meetEntxListLeft}>
          <Typography variant="h2" color="gray25">
            Meet ENTX
          </Typography>
          <Typography variant="bodyMd" color="gray300">
            We love live entertainment, but we don’t like the industry’s state of play. So we have purpose-built a new ecosystem, to address challenges and support the folks who make live entertainment possible - from the vital grassroots, through to sold out stadiums and beyond.
          </Typography>
        </div>
        <div className={styles.meetEntxListRight}>
          <span className={styles.meetEntxListTitle}>Here’s what we stand for:</span>
          <ul className={styles.meetEntxList}>
            {ITEMS.map((item, index) => (
                <li key={index}>
                  <div>
                    <Typography variant="h4" color="white">
                      {item.title}
                    </Typography>
                    <Typography variant="bodyMd" color="white">
                      {item.description}
                    </Typography>
                  </div>
                </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default MeetENTX;
