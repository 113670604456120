import Carousel from './components/Carousel/Carousel';
import Discover from './components/Discover/Discover';

const Events = () => (
  <>
    <Carousel />
    <Discover />
  </>
);

export default Events;
