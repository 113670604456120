import cn from 'classnames';
import { LeftIcon, RightIcon } from 'assets/svg';
import { Button } from 'components';
import styles from './SlickArrows.module.scss';

interface SlickArrowsProps {
  currentSlide: number;
  slideCount: number;
  onClick?: () => void;
}

export const SlickArrowNext = ({ currentSlide, ...props }: SlickArrowsProps) => (
  <div className={cn(styles.arrow, styles.prev)}>
    <Button
      variant="secondary"
      circle
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      size="L"
      disabled={!props.onClick}
      onClick={props.onClick}>
      <LeftIcon />
    </Button>
  </div>
);

export const SlickArrowPrev = ({ currentSlide, slideCount, ...props }: SlickArrowsProps) => (
  <div className={cn(styles.arrow, styles.next)}>
    <Button
      variant="secondary"
      circle
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      onClick={props.onClick}
      size="L"
      disabled={!props.onClick}
      type="button">
      <RightIcon />
    </Button>
  </div>
);
