import { ROUTES } from 'consts';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  Login,
  Events,
  EventDetails,
  PurchaseSummary,
  PurchaseCompleted,
  PurchaseProcess,
  AuthCallback,
  PrivacyPolicy,
  TermsOfService,
  TicketsAllocation,
  EventStream,
  CheckIn,
  CustomerPortal,
} from 'pages';
import { Layout, ProtectedRoute } from 'components';
import MyEvents from 'pages/MyEvents/MyEvents';
import PublicRoute from 'components/PublicRoute/PublicRoute';
import VerifyID from 'pages/VerifyID/VerifyID';
import { useAppSelector } from 'store';
import Sumsub from './pages/SumSub/SumSub';

const App = () => {
  const { isVerified } = useAppSelector((state) => state.auth);

  return (
    <Routes>
      <Route
        path={ROUTES.HOMEPAGE}
        element={
          <PublicRoute>
            <Navigate to={ROUTES.LOGIN} replace />
          </PublicRoute>
        }
      />

      <Route
        path={ROUTES.LOGIN}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.SIGNUP}
        element={
          <PublicRoute>
            <Login isSignUp />
          </PublicRoute>
        }
      />
      {!isVerified && (
        <Route
          path={ROUTES.VERIFY_ID}
          element={
            <ProtectedRoute>
              <VerifyID />
            </ProtectedRoute>
          }
        />
      )}
      <Route element={<Layout />}>
        <Route path={ROUTES.EVENTS} element={<Events />} />
        <Route path={ROUTES.EVENT} element={<EventDetails />} />
        <Route
          path={ROUTES.STREAM}
          element={
            <ProtectedRoute>
              <EventStream />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.MY_EVENTS}
          element={
            <ProtectedRoute>
              <MyEvents />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE}
          element={
            <ProtectedRoute>
              <PurchaseSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE_TICKET}
          element={
            <ProtectedRoute>
              <PurchaseSummary ticket />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.TICKETS_ALLOCATION}
          element={
            <ProtectedRoute>
              <TicketsAllocation />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE_PROCESS}
          element={
            <ProtectedRoute>
              <PurchaseProcess />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE_TICKET_PROCESS}
          element={
            <ProtectedRoute>
              <PurchaseProcess ticket />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE_COMPLETED}
          element={
            <ProtectedRoute>
              <PurchaseCompleted />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE_TICKET_COMPLETED}
          element={
            <ProtectedRoute>
              <PurchaseCompleted />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.SUMSUB}
          element={
            <ProtectedRoute>
              <Sumsub />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PURCHASE_TICKET_PROCESS_SUMSUB}
          element={
            <ProtectedRoute>
              <Sumsub eventOnly />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.PRIVACY_POLICY}
          element={
            <PublicRoute redirect={false}>
              <PrivacyPolicy />
            </PublicRoute>
          }
        />

        <Route
          path={ROUTES.TERMS_OF_SERVICE}
          element={
            <PublicRoute redirect={false}>
              <TermsOfService />
            </PublicRoute>
          }
        />
      </Route>
      <Route
        path={ROUTES.AUTH_CALLBACK}
        element={
          <PublicRoute>
            <AuthCallback />
          </PublicRoute>
        }
      />
      <Route path={ROUTES.NOT_FOUND} element={<Navigate to={ROUTES.EVENTS} />} />
      <Route
        path={ROUTES.CHECK_IN}
        element={
          <ProtectedRoute>
            <CheckIn />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTES.CUSTOMER_PORTAL}
        element={
          <ProtectedRoute>
            <CustomerPortal />
          </ProtectedRoute>
        }
      />
      <Route path={ROUTES.NOT_FOUND} element={<div>404 - Not Found</div>} />
    </Routes>
  );
};

export default App;
