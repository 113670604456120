import { EmptyImageIcon } from 'assets/svg';
import { ButtonLink, Typography } from 'components';
import styles from './EventsEmpty.module.scss';

const EventsEmpty = () => (
  <div className="container">
    <Typography variant="h3" weight="bold" className="p-y-48">
      My Events
    </Typography>
    <div className={styles.empty}>
      <EmptyImageIcon className="m-b-32" />
      <Typography variant="bodyXl" color="gray50" className="m-b-32">
        You don’t have any shows listed. When you do you’ll see them here.
      </Typography>
      <ButtonLink variant="secondary" to="/events" size="L" width="208px">
        Browse Events
      </ButtonLink>
    </div>
  </div>
);

export default EventsEmpty;
