import { Navigate, useParams } from 'react-router';
import { useState, useEffect, useRef, FC, useMemo } from 'react';
import { Breadcrumbs, Button, Countdown, Divider, QuantitySelector, Tooltip, Typography } from 'components';
import PurchaseItem from './components/PurchaseItem/PurchaseItem';
import { priceFormatter } from 'helpers';
import UnverifiedUserModal from '../PurchaseProcess/components/UnverifiedUserModal/UnverifiedUserModal';
import { useAppDispatch, useAppSelector, useGetAccountQuery, useGetOneEventQuery } from 'store';
import { ROUTES } from 'consts';
import { updateQuantity } from 'store/slices/event';
import { useAppNavigate } from 'hooks';
import styles from './PurchaseSummary.module.scss';
import { HelpCircleIcon } from 'assets/svg';
import { EventType, SumsubReviewStatus } from 'types';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA;

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface PurchaseSummaryProps {
  ticket?: boolean;
}

const PurchaseSummary: FC<PurchaseSummaryProps> = ({ ticket }) => {
  const { navigateTo } = useAppNavigate();

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [isVisibleUnverifiedUserModal, setIsVisibleUnverifiedUserModal] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const recaptchaRef = useRef<HTMLDivElement>(null);

  const { isVerified } = useAppSelector((state) => state.auth);

  const [timesUp, setTimesUp] = useState(false);

  const [quantity, setQuantity] = useState(1);
  // const { quantity } = useAppSelector((state) => state.event);
  // const setQuantity = (quantity: number) => dispatch(updateQuantity(quantity));

  const toggleShowUnverifiedUserModal = () => setIsVisibleUnverifiedUserModal(!isVisibleUnverifiedUserModal);
  const { data: event, isLoading: eventLoading } = useGetOneEventQuery({ id: id! });
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery();

  const shouldShowKyc = useMemo(() => {
    return event?.type !== EventType.Online && (!account?.sumsub || account?.sumsub.reviewStatus !== SumsubReviewStatus.COMPLETED);
  }, [account, event]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.onload = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(async () => {
          try {
            const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
            if (token) {
              setRecaptchaVerified(true);
            }
          } catch (error) {
            console.error('reCAPTCHA verification failed', error);
          }
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  if (!id) return <Navigate to={ROUTES.EVENTS} />;
  if (eventLoading || isAccountLoading) return <div>Loading...</div>;
  if (!event) return <Navigate to={ROUTES.EVENTS} />;
  if (ticket && event.type === EventType.Online) return <Navigate to={ROUTES.EVENTS} />

  const proceedToPayment = () => {
    if (isVerified) {
      navigateTo(ticket ? 'PURCHASE_TICKET_PROCESS' : 'PURCHASE_PROCESS', { id }, undefined, { from: ROUTES.PURCHASE, quantity });
    } else {
      toggleShowUnverifiedUserModal();
    }
  };

  return (
    <>
      <div className="container">
        <Breadcrumbs title="Event details" to={ROUTES.EVENT.replace(':id', id)} />
      </div>
      <div className={styles.purchase}>
        <Typography variant="h4" weight="medium" className="m-b-48">
          Summary
        </Typography>
        <PurchaseItem event={event} />
        {
          ticket ? (
            <div className={styles.purchaseQuantity}>
              <div>
                <Typography variant="bodyMd" weight="semibold" className={styles.purchaseQuantityNumber}>
                  Number of tickets
                  <Tooltip
                    className={styles.purchaseQuantityNumberTooltip}
                    content={
                      <Typography variant="bodyXs">
                        Buying multiple tickets? You’ll be able to allocate them after purchase ✅
                      </Typography>
                    }
                    placement="top-start"
                    withArrow
                    offset={[-13, 20]}>
                    <HelpCircleIcon className={styles.purchaseQuantityNumberIcon} />
                  </Tooltip>
                </Typography>
                <Typography variant="bodyS" weight="medium" color="gray300">
                  {priceFormatter(event.price)} each
                </Typography>
              </div>
              <QuantitySelector quantity={quantity} setQuantity={setQuantity} max={6} />
            </div>
          ) : null
        }
        <Divider className="m-t-32 m-b-32" />
        <div className={styles.purchaseTotal}>
          <Typography variant="h7" weight="semibold">
            Total:
          </Typography>
          <Typography variant="bodyL" weight="medium">
            {priceFormatter(event.price * quantity)}
          </Typography>
        </div>
        <div className={styles.purchaseButton}>
          <Button
            onClick={shouldShowKyc ? toggleShowUnverifiedUserModal : proceedToPayment}
            variant="primary"
            size="M"
            disabled={!recaptchaVerified}
          >
            Proceed to Payment
          </Button>
        </div>
      </div>
      {isVisibleUnverifiedUserModal && (
        <UnverifiedUserModal visible={isVisibleUnverifiedUserModal} closeModal={toggleShowUnverifiedUserModal} handleOk={proceedToPayment} />
      )}
      <div ref={recaptchaRef} />
    </>
  );
};

export default PurchaseSummary;
