import { Typography } from 'components';

interface InfoItemProps {
  title: string;
  value: string | number;
}

const InfoItem: React.FC<InfoItemProps> = ({ title, value }) => (
  <>
    <Typography variant="bodyMd" color="gray300" className="m-b-8">
      {title}
    </Typography>
    <Typography variant="bodyMd" weight="medium" color="white">
        {title === 'Duration'
            ? value >= 60
                ? `${Math.floor(Number(value) / 60)} hr${Number(value) % 60 ? ` ${Number(value) % 60} min` : ''}`
                : `${value} min`
            : value}
    </Typography>
  </>
);

export default InfoItem;
