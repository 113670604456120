import { useEffect } from 'react';
import {useCustomerPortalQuery} from "../../store";

const CustomerPortal = () => {
    const { data } = useCustomerPortalQuery();

    useEffect(() => {
        try {
            if (data && data.url) {
                window.location.href = data.url;
            }
        } catch (error) {
            console.error('Error opening customer portal:', error);
        }
    }, [data]);

    return <p>Redirecting to customer portal...</p>;
};

export default CustomerPortal;