import { AlertCircleIcon, CheckCircleIcon, ErrorIcon } from 'assets/svg';
import { toast } from 'react-toastify';

const notify = {
  warning: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.warning(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
    autoClose,
    toastId,
    icon: () => <ErrorIcon />,
  }),
  error: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.error(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
    autoClose,
    toastId,
    icon: () => <AlertCircleIcon />,
  }),
  success: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.success(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
    autoClose,
    toastId,
    icon: () => <CheckCircleIcon />,
  }),
  info: (title: string, message?: string, autoClose?: number, toastId?: string) => toast.info(
    <>
      <div className="Toastify__toast-title">{title}</div>
      {message &&
        <div className="Toastify__toast-message">{message}</div>
      }
    </>, {
    autoClose,
    toastId,
    icon: () => <ErrorIcon />,
  }),
};

export {
  notify,
};