import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EventState {
  quantity: number;
}

const initialState: EventState = {
  quantity: 1,
};

export const eventSlice = createSlice({
  name: 'Event',
  initialState,
  reducers: {
    updateQuantity(state, action: PayloadAction<number>) {
      state.quantity = action.payload;
    },
  },
});

export const { updateQuantity } = eventSlice.actions;

export default eventSlice.reducer;
