import { Link, Typography } from 'components';
import { ChevronLeftIcon } from 'assets/svg';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
  title: string;
  to: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ title, to }) => {
  return (
    <section className={styles.breadcrumbs}>
      <Link to={to} className={styles.breadcrumbsLink}>
        <ChevronLeftIcon />
        <Typography variant="bodyMd" weight="medium" color="gray200">
          {title}
        </Typography>
      </Link>
    </section>
  );
};

export default Breadcrumbs;
