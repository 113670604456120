import { CloseCircleIcon } from 'assets/svg';
import { Button, Typography } from 'components';
import styles from './BlockEntryOption.module.scss';

interface BlockEntryOptionProps {
  onConfirm: () => void;
  onClose: () => void;
}

const BlockEntryOption = ({ onConfirm, onClose }: BlockEntryOptionProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography variant="bodyXl">Are you sure you want to block entry for this guest?</Typography>
      <CloseCircleIcon className={styles.headerCloseIcon} onClick={onClose} />
    </div>

    <Typography variant="bodyMd">Once blocked, the guest will not be able to enter.</Typography>

    <div className={styles.buttonWrapper}>
      <Button variant="secondary" width="100%" type="button" size="M" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" width="100%" type="button" size="M" className={styles.button} onClick={onConfirm}>
        Block Entry
      </Button>
    </div>
  </div>
);

export default BlockEntryOption;
