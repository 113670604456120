import { CloseCircleIcon } from 'assets/svg';
import { Button, Typography } from 'components';
import styles from './DenyEntryOption.module.scss';

interface DenyEntryOptionProps {
  onConfirm: () => void;
  onClose: () => void;
}

const DenyEntryOption = ({ onConfirm, onClose }: DenyEntryOptionProps) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Typography variant="bodyXl">Are you sure you want to deny entry for this guest?</Typography>
      <CloseCircleIcon className={styles.headerCloseIcon} onClick={onClose} />
    </div>

    <Typography variant="bodyMd">
      The guest will be able to enter again once the reason for the denial is resolved.
    </Typography>

    <div className={styles.buttonWrapper}>
      <Button variant="secondary" width="100%" type="button" size="M" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" width="100%" type="button" size="M" className={styles.button} onClick={onConfirm}>
        Deny Entry
      </Button>
    </div>
  </div>
);

export default DenyEntryOption;
