import cn from 'classnames';
import Typography from 'components/Typography/Typography';
import { formatDateLong, formatTime } from 'helpers';
import { EventType, IEvent } from 'types';
import styles from './CarouselItem.module.scss';
import Button from 'components/Button/Button';
import { Link } from 'components';
import { useAppNavigate } from 'hooks';
import { EventLiveIcon, EventOfflineIcon } from 'assets/svg';

const ICONS_MAP: { [key: string]: JSX.Element } = {
  online: <EventLiveIcon />,
  offline: <EventOfflineIcon />,
};

interface CarouselItemProps {
  event: IEvent;
  currentSlide: number;
  index: number;
  eventsLength: number;
  bought?: boolean;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ event, currentSlide, index, eventsLength, bought }) => {
  const { navigateTo } = useAppNavigate();
  const isAuth = true;

  const navigateToPurchase = (id: string, ticket?: boolean) => {
    if (isAuth) {
      if (ticket) {
        navigateTo('PURCHASE_TICKET', { id });
      } else {
        navigateTo('PURCHASE', { id });
      }
    } else {
      navigateTo('LOGIN');
    }
  };

  return (
    <div
      className={cn(styles.carouselItem, {
        [styles.active]: currentSlide === index,
      })}
      style={{ width: '758px' }}>
      <ul className={styles.carouselItemType}>
        <li className={cn(styles.carouselItemTypeItem, { [styles.live]: event.live && event.type === 'online' })}>
          {ICONS_MAP[event.type]}
          {event.live && 'Live'}
        </li>
      </ul>
      <div className={styles.carouselItemBlur}>
        <img src={event.heroBanner} width={854} height={393} alt={event.title} />
      </div>
      <Link
        to={`/events/${event.id}`}
        className={cn(styles.carouselItemImage, {
          [styles.disabled]: currentSlide !== index,
          [styles.prev]: index === (currentSlide - 1 + eventsLength) % eventsLength,
          [styles.next]: index === (currentSlide + 1) % eventsLength,
        })}>
        <img src={event.heroBanner} width={758} height={427} alt={event.title} />
      </Link>
      <div className={styles.carouselItemContent}>
        <div className={styles.carouselItemDate}>
          <Typography variant="bodyMd" weight="semibold" color="primary500">
            {formatDateLong(event.startDate)}
          </Typography>
          <Typography variant="bodyMd" color="primary500">
            |
          </Typography>
          <Typography variant="bodyMd" weight="semibold" color="primary500">
            {formatTime(event.startDate)}
          </Typography>
        </div>
        <Link to={`/events/${event.id}`}>
          <Typography variant="h2" weight="semibold" className="m-b-24">
            {event.title}
          </Typography>
        </Link>
        {!event.passed && !event.inviteOnly && !bought && (
          <div className={styles.buttonsList}>
            {[EventType.Hybrid, EventType.Online].includes(event.type) ? (
              <Button variant="primary" size="M" onClick={() => navigateToPurchase(event.id)}>
                Buy Stream
              </Button>
            ) : null}
            {[EventType.Hybrid, EventType.Offline].includes(event.type) ? (
              <Button variant={EventType.Hybrid ? "secondary" : "primary"} size="M" onClick={() => navigateToPurchase(event.id, true)}>
                Buy Ticket
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default CarouselItem;
