import { ACCESS_TOKEN, ROUTES } from 'consts';
import { FC } from 'react';
import { Navigate } from 'react-router';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (!accessToken) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }
  return children;
};

export default ProtectedRoute;
