import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'helpers';
import { IReqArgs } from 'types';

interface ISumsubResponse {
    token: string;
}

export const sumsubApi = createApi({
    reducerPath: 'sumsub',
    baseQuery: getBaseQuery('/sumsub'),
    endpoints: (builder) => ({
        getUserAccessToken: builder.query<string, IReqArgs | void>({
            query: ({ config } = {}) => ({
                url: '/init',
                _enableErrorHandler: config?._enableErrorHandler,
            }),
            transformResponse: (response: ISumsubResponse) => {
                return response.token;
            },
        }),
    }),
});

export const { useGetUserAccessTokenQuery } = sumsubApi;
