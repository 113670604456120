import dayjs from 'dayjs';

const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];

export const formatDate = (date: string) => {
  return `${dayjs(date).format('DD MMM YYYY')} ${timezone}`;
};

export const formatDateLong = (date: string) => {
  return `${dayjs(date).format('ddd, D MMMM YYYY')} ${timezone}`;
};

export const formatTime = (date: string) => {
  return dayjs(date).format('h:mm A');
};

export const formatDateTime = (date: string) => {
  return dayjs(date).format('DD/MM/YYYY [at] h:mm A');
};
