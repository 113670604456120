import cn from 'classnames';
import { MinusIcon, PlusIcon } from 'assets/svg';
import Typography from 'components/Typography/Typography';
import styles from './QuantitySelector.module.scss';

interface QuantitySelectorProps {
  min?: number;
  max?: number;
  quantity?: number;
  setQuantity: (quantity: number) => void;
}

const QuantitySelector = ({ quantity = 1, setQuantity, min = 1, max = 10 }: QuantitySelectorProps) => {
  const decrement = () => {
    if (quantity > min) setQuantity(quantity - 1);
  };

  const increment = () => {
    if (quantity < max) setQuantity(quantity + 1);
  };

  return (
    <div className={styles.quantitySelector}>
      <button
        onClick={decrement}
        className={cn(styles.quantitySelectorButton, { [styles.disabled]: quantity === min })}
        disabled={quantity === min}>
        <MinusIcon />
      </button>
      <Typography variant="h6" weight="semibold" className={styles.quantitySelectorCount} color="white">
        {quantity}
      </Typography>
      <button
        onClick={increment}
        className={cn(styles.quantitySelectorButton, { [styles.disabled]: quantity === max })}
        disabled={quantity === max}>
        <PlusIcon />
      </button>
    </div>
  );
};

export default QuantitySelector;
