import { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Divider.module.scss';

interface DividerProps {
  children?: ReactNode;
  className?: string;
}
const Divider: FC<DividerProps> = ({
  children,
  className
}) => {
  return (
    <div className={cn(styles.divider, className)}>
      {
        children ? (
          <>
            <span className={styles.line}></span>
            <span className={styles.text}>{children}</span>
            <span className={styles.line}></span>
          </>
        ) : (
          <span className={styles.line}></span>
        )
      }
    </div>
  );
};

export default Divider;