import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'helpers';
import { IReqArgs } from 'types';

interface IPaymentCheckoutPayload {
  eventId: string;
}

interface IPaymentTipPayload {
  artistId: string;
  amount: number;
}

export const paymentApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: getBaseQuery('/payments'),
  endpoints: (builder) => ({
    config: builder.query<{ publicKey: string }, IReqArgs | void>({
      query: ({ config } = {}) => ({
        url: '/config',
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    checkout: builder.mutation<{ clientSecret: string }, IReqArgs<IPaymentCheckoutPayload>>({
      query: ({ eventId, config }) => ({
        url: '/checkout',
        method: 'POST',
        body: { eventId },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    tip: builder.mutation<{ clientSecret: string }, IReqArgs<IPaymentTipPayload>>({
      query: ({ amount, artistId, config }) => ({
        url: '/tip',
        method: 'POST',
        body: { amount, artistId },
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    checkEventStatus: builder.query<{ status: string }, IReqArgs<{ id: string }>>({
      query: ({ id, config }) => ({
        url: `/event/${id}/status`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    customerPortal: builder.query<{ url: string }, IReqArgs | void>({
      query: ({ config } = {}) => ({
        url: '/create-customer-portal',
        method: 'POST',
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
  }),
});

export const { useConfigQuery, useTipMutation, useCheckoutMutation, useCheckEventStatusQuery, useLazyCheckEventStatusQuery, useCustomerPortalQuery } = paymentApi;
