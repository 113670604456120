import { QRFrameIcon } from 'assets/svg';
import { notify } from 'helpers';
import QRScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import TicketInfo from '../TicketInfo/TicketInfo';
import styles from './QRCodeReader.module.scss';

interface IScanResultData {
  ticketId: string;
}

interface QRCodeReaderProps {
  eventId: string;
}

const QRCodeReader = ({ eventId }: QRCodeReaderProps) => {
  const scannerRef = useRef<QRScanner>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const qrBoxRef = useRef<HTMLDivElement>(null);
  const [qrOn, setQROn] = useState<boolean>(true);
  const [scanResult, setScanResult] = useState<IScanResultData | null>(null);

  const handleScanSuccess = (result: QRScanner.ScanResult) => {
    try {
      const scanResultData = JSON.parse(result?.data);
      setScanResult(scanResultData);
    } catch (error) {
      notify.error('QR Code is not valid');
      console.log(error);
    }
  };

  const handleClose = () => {
    setScanResult(null);
  };

  useEffect(() => {
    const videoRefCurrent = videoRef?.current;

    if (videoRefCurrent && !scannerRef.current) {
      scannerRef.current = new QRScanner(videoRefCurrent, handleScanSuccess, {
        preferredCamera: 'environment',
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxRef?.current || undefined,
      });

      scannerRef.current.start().catch(() => setQROn(false));
    }
    return () => {
      if (!videoRefCurrent) {
        scannerRef?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      notify.error('Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.');
    }
  }, [qrOn]);

  return (
    <div className={styles.qrReader}>
      <video ref={videoRef} className={styles.qrReaderVideo}></video>
      <div ref={qrBoxRef} className={styles.qrReaderBox}>
        <QRFrameIcon width={256} height={256} className={styles.qrReaderFrame} />
      </div>

      {scanResult && <TicketInfo {...scanResult} eventId={eventId} onClose={handleClose} />}
    </div>
  );
};

export default QRCodeReader;
