import { EventItem, Player, SupportCreator, Typography } from 'components';
import EventsEmpty from './components/EventsEmpty/EventsEmpty';
import styles from './MyEvents.module.scss';
import { useGetMyEventsQuery } from 'store';

const MyEvents = () => {
    const { data } = useGetMyEventsQuery(undefined, { refetchOnMountOrArgChange: true });
    const events = data ?? [];

  if (!events.length) {
    return <EventsEmpty />;
  }

  return (
    <div className="container">
      <Typography variant="h3" weight="bold" className="p-y-48">
        My Events
      </Typography>
      <div>
        <Typography variant="h5" weight="bold" className="m-b-32">
          Upcoming
        </Typography>
        <div className={styles.eventsList}>
          {events.map((item) => (
            <EventItem key={item.id} event={item} bought myEvents />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyEvents;
