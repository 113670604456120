import { Typography } from 'components';
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => (
    <div className={styles.privacy}>
        <Typography variant="h1" align="center">Privacy Policy</Typography>
        <div className={styles.privacyDate}>Effective Date: 5th February 2025</div>
        <Typography variant="h5" className={styles.privacyTitle}>1. Introduction</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>ENTX Ltd ("we", "us", or "our") values your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, and protect your personal information when you use our Software as a Service (SaaS) platform.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>2. Information We Collect</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>
            <p className={styles.privacyListTitle}>We collect the following types of personal data:</p>
            <ul className={styles.list}>
                <li>Account Information: Name, email address, username, and password.</li>
                <li>Payment Information: Credit card or other payment details.</li>
                <li>Usage Data: Information about how you use our services (e.g., features accessed, time spent on the platform, etc.).</li>
                <li>Communication Data: Information you provide when contacting our support team.</li>
            </ul>
        </Typography>
        <Typography variant="h5" className={styles.privacyTitle}>3. How We Use Your Information</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>
            <p className={styles.privacyListTitle}>We use your personal information for the following purposes:</p>
            <ul className={styles.list}>
                <li>To provide and maintain our services.</li>
                <li>To process payments and subscriptions.</li>
                <li>To improve our services and offer customer support.</li>
                <li>To send you marketing communications (if you have opted in).</li>
                <li>To comply with legal obligations.</li>
        </ul>
        </Typography>
        <Typography variant="h5" className={styles.privacyTitle}>4. Sharing Your Information</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>We do not sell or rent your personal data to third parties. We may share your data with trusted service providers who assist in operating our services, such as payment processors or hosting providers. We will ensure that these parties are bound by confidentiality agreements and handle your data securely.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>5. Data Retention</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>We will retain your personal information for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy or as required by law. You can request that we delete your account and personal information at any time, subject to any legal obligations.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>6. Security</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>We use industry-standard security measures to protect your personal data.
            However, no method of transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>7. Your Rights</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>
            <p className={styles.privacyListTitle}>Depending on your location and applicable laws, you may have the following
                rights regarding your personal data:</p>
            <ul className={styles.list}>
                <li>The right to access and update your data.</li>
                <li>The right to request deletion of your data.</li>
                <li>The right to withdraw consent for processing (if applicable).</li>
                <li>The right to object to processing or request data portability.</li>
            </ul>
        </Typography>
        <Typography variant="h5" className={styles.privacyTitle}>8. Cookies</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>We may use cookies to enhance your user experience, analyse usage patterns, and track preferences. You can control cookie settings through your browser settings.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>9. Children’s Privacy</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>Our services are not intended for individuals under the age of 13, and we
            do not knowingly collect personal information from children. If we learn that we have collected personal data from a child under the age of 13, we will take steps to delete that information.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>10. Changes to This Privacy Policy</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>We may update this Privacy Policy from time to time. Any changes will be posted on our website with an updated effective date. We encourage you to review this policy periodically.</Typography>
        <Typography variant="h5" className={styles.privacyTitle}>11. Contact Information</Typography>
        <Typography variant="bodyMd" className={styles.privacyBody}>If you have any questions about this Privacy Policy or want to exercise your rights regarding your personal data, please contact us at <a href="mailto:support@entx.io" className={styles.mailto}>support@entx.io</a></Typography>
    </div>
);

export default PrivacyPolicy;
