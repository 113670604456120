import { useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppNavigate } from 'hooks';
import { Link, NavLink } from 'react-router';
import Typography from 'components/Typography/Typography';
import { EventsIcon, LogoutIcon, CheckCircleIcon, EventOfflineIcon, ShowIcon } from 'assets/svg';
import { useOutsideClick } from 'hooks';
import styles from './HeaderDropdown.module.scss';
import { ROUTES } from 'consts';
import { useAppSelector, useGetAccountQuery } from 'store';
import ButtonLink from 'components/Button/ButtonLink';
import { SumsubReviewStatus } from 'types';

interface HeaderDropdownProps {
  children: React.ReactNode;
}

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({ children }) => {
  const { data: accountData, isLoading: isGetAccountLoading } = useGetAccountQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { navigateTo } = useAppNavigate();
  const toggleOpen = () => setIsOpen((prev) => !prev);

  const unfinishedVerify = useMemo(() => {
    return accountData && accountData.sumsub && accountData.sumsub.reviewStatus !== SumsubReviewStatus.COMPLETED;
  }, [accountData])

  const dropdownRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  const logout = () => {
    localStorage.clear();
    navigateTo('LOGIN');
  };

  useOutsideClick(dropdownRef, () => isOpen && toggleOpen());

  useEffect(() => {
    const scrollEvent = () => {
      window.addEventListener('scroll', () => {
        if (isOpen) {
          setIsOpen(false);
        }
      });
    };
    scrollEvent();
    return () => window.removeEventListener('scroll', scrollEvent);
  }, [isOpen]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button className={styles.dropdownButton} onClick={toggleOpen}>
        {children}
        {/* ADD CHECK OF ALLOCATION TICKETS*/}
        {unfinishedVerify && <span className={styles.dropdownBudge} />}
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={30000}
        unmountOnExit
        classNames={{
          enter: styles.dropdownTransitionEnter,
          enterActive: styles.dropdownTransitionEnterActive,
          exit: styles.dropdownTransitionExit,
          exitActive: styles.dropdownTransitionExitActive,
        }}>
        <div className={styles.dropdownOptions} ref={nodeRef}>
          {unfinishedVerify && (
            <div className="m-b-8">
              <ButtonLink to={ROUTES.SUMSUB} variant="secondary" size="S" width="100%">
                Verify Your ID
              </ButtonLink>
            </div>
          )}
          <ul className={styles.list}>
            <li className={styles.listItem} onClick={toggleOpen}>
              <Link className={styles.listElement} to={ROUTES.MY_EVENTS}>
                <EventsIcon />
                <Typography variant="bodyMd">
                  My Events
                  {/* <span className={styles.listElementBudge} /> */}
                </Typography>
              </Link>
            </li>
            {/*<li className={styles.listItem} onClick={toggleOpen}>*/}
            {/*  <Link className={styles.listElement} to={ROUTES.SUMSUB}>*/}
            {/*    <CheckCircleIcon />*/}
            {/*    <Typography variant="bodyMd">Identity verification</Typography>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className={styles.listItem} onClick={toggleOpen}>
              <Link className={styles.listElement} to={ROUTES.CUSTOMER_PORTAL}>
                <EventOfflineIcon />
                <Typography variant="bodyMd">Billing portal</Typography>
              </Link>
            </li>
            <li className={styles.listItem} onClick={toggleOpen}>
              <NavLink className={styles.listElement} to="https://entx.io/">
                <ShowIcon />
                <Typography variant="bodyMd">About ENTX</Typography>
              </NavLink>
            </li>
            <li className={styles.listItem} onClick={toggleOpen}>
              <button className={styles.listElement} onClick={logout}>
                <LogoutIcon />
                <Typography variant="bodyMd">Logout</Typography>
              </button>
            </li>
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderDropdown;
