import { useState, useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { LogoIcon } from 'assets/svg';
import LoginButtons from './LoginButtons/LoginButtons';
import UserProfile from './UserProfile/UserProfile';
import styles from './Header.module.scss';
import { ACCESS_TOKEN } from 'consts';

interface HeaderProps {
  landing?: boolean;
  hideProfile?: boolean;
}

const Header: React.FC<HeaderProps> = ({ landing = false, hideProfile = false }) => {
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <header
      className={cn(styles.header, {
        [styles.hide]: !isScrollingUp,
        [styles.background]: lastScrollTop > 0,
        [styles.landing]: landing,
      })}>
      <div className="container">
        <div className={styles.headerRow}>
          {hideProfile ? (
            <LogoIcon />
          ) : (
            <Link to="/" onClick={() => window.scrollTo(0, 0)} className={styles.logo}>
              <LogoIcon />
            </Link>
          )}
          {!hideProfile && <div>{accessToken ? <UserProfile /> : <LoginButtons />}</div>}
        </div>
      </div>
    </header>
  );
};

export default Header;
