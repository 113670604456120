import { ButtonLink, Typography } from 'components';
import { LogoTicketIcon } from 'assets/svg';
import { ROUTES } from 'consts';
import styles from './PurchaseCompleted.module.scss';
import { Navigate, useLocation, useParams } from 'react-router';
import { useAppSelector, useGetOneEventQuery } from 'store';
import { formatDateLong, formatTime, priceFormatter } from 'helpers';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { useAppNavigate } from 'hooks';

interface PurchaseCompletedProps {
  ticket?: boolean;
}

const PurchaseCompleted: FC<PurchaseCompletedProps> = ({ ticket }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { navigateTo } = useAppNavigate();
  const { data: event, isLoading, error } = useGetOneEventQuery({ id: id! });

  useEffect(() => {
    if (!location.state || ![ROUTES.PURCHASE_PROCESS, ROUTES.PURCHASE_TICKET_PROCESS, ROUTES.PURCHASE_TICKET_PROCESS_SUMSUB].includes(location.state.from)) {
      navigateTo('EVENTS');
    }
  }, [location]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading event.</div>;
  if (!id || !event) return <Navigate to={ROUTES.EVENTS} />;

  return (
    <div className={styles.purchaseCompleted}>
      <div className={styles.purchaseCompletedHeader}>
        <Typography variant="h4" weight="medium" color="gray25" className="m-b-16">
          It's on! 🎉
        </Typography>
        <Typography variant="bodyMd" color="gray25">
          Your purchase was completed successfully.
        </Typography>
      </div>
      <div className={styles.ticket}>
        <div className={styles.ticketImage}>
          <img src={event.heroBanner} alt={event.title} width={264} height={264} />
        </div>
        <div className={styles.ticketContent}>
          <div className={styles.ticketContentInfo}>
            <Typography variant="h6" weight="medium" color="gray950" className={styles.ticketContentTitle}>
              {event.title}
            </Typography>
            <p className={styles.ticketContentLocation}>Location ({event.venue})</p>
            <p className={styles.ticketContentDate}>{formatDateLong(event.startDate)}</p>
            <div className={styles.ticketContentTime}>
              <div className={styles.ticketContentTimeItem}>
                <span className={styles.ticketContentTimeValue}>{formatTime(event.startDate)}</span>
                <span className={styles.ticketContentTimeKey}>Local (GMT{dayjs().format('Z')})</span>
              </div>
              <div className={styles.ticketContentTimeItem}>
                <span className={styles.ticketContentTimeValue}>{formatTime(event.startDate)}</span>
                <span className={styles.ticketContentTimeKey}>
                  {event.venue} (GMT{dayjs(event.startDate).format('Z')})
                </span>
              </div>
            </div>
            <div className={styles.ticketContentFooter}>
              <p>x{location.state?.quantity || 1} Ticket(s)</p>
              <p className={styles.ticketContentFooterPrice}>{priceFormatter(event.price * (location.state?.quantity || 1))}</p>
            </div>
          </div>
          <div className={styles.ticketContentLogo}>
            <LogoTicketIcon />
          </div>
        </div>
      </div>
      <div className={styles.purchaseCompletedFooter}>
        <Typography variant="bodyS" color="gray300" className="m-b-24">
          You’ll receive email confirmation with your unique link. <br />
          It won’t go live until the day of the event.
        </Typography>
        <div>
          <ButtonLink to={ROUTES.EVENTS} variant="secondary" width="239px" size="L">
            Browse all events
          </ButtonLink>
          <ButtonLink to={`/events/${event.id}`} variant="primary" width="221px" size="L" className={styles.purchaseCompletedFooterBtn}>
            View event
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCompleted;
