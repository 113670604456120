import { FacebookIcon, GoogleIcon, HelpCircleIcon } from 'assets/svg';
import styles from './LoginStep.module.scss';
import { Button, ButtonLink, Divider, InputField, Tooltip, Typography } from 'components';
import { FC, useMemo, useRef, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { setEmail, useRequestCodeMutation } from 'store';
import { useDispatch } from 'react-redux';
import { FACEBOOK_AUTH_URL, GOOGLE_AUTH_URL } from 'consts';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA;

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

interface LoginStepProps {
  onSuccessEmailContinue: () => void;
  isSignUp?: boolean;
}

interface EmailForm {
  email: string;
}

const LoginStep: FC<LoginStepProps> = ({ onSuccessEmailContinue, isSignUp = false }) => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef<HTMLDivElement>(null);

  const schema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .required('Please enter your email address to continue.')
          .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
      }),
    [],
  );

  const {
    control,
    handleSubmit,
  } = useForm<EmailForm>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const [requestCode, { isLoading }] = useRequestCodeMutation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleRecaptchaVerify = async (email: string) => {
    if (!window.grecaptcha) {
      console.error('reCAPTCHA not load');
      return;
    }

    const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });

    const testToken = token || 'test_8o4jskkf02llfpqwncxz';

    await onSubmit({ email, token: testToken });
  };

  const onSubmit = async ({ email, token }: { email: string; token: string }) => {
    try {
      await requestCode({ email, recaptchaToken: token, config: { _enableErrorHandler: true } }).unwrap();
      dispatch(setEmail(email));
      onSuccessEmailContinue();
    } catch (e) {
      console.log(e);
    }
  };

  const authWithGoogle = () => {
    window.location.href = GOOGLE_AUTH_URL;
  };
  const authWithFacebook = () => {
    window.location.href = FACEBOOK_AUTH_URL;
  };

  return (
    <>
      <Typography className="m-b-12" variant="h4" weight="semibold">
        {isSignUp ? 'Sign Up' : 'Log In'}
      </Typography>
      <Typography className="m-b-32" color="gray300" variant="bodyS">
        {isSignUp ? 'Set up your account and dive in.' : 'Welcome back! Please enter your details'}
      </Typography>
      <Button
        variant="secondary"
        width="100%"
        className={cn(styles.socialButton, 'm-b-12')}
        onClick={authWithGoogle}
        size="M"
        iconLeft={<GoogleIcon />}>
        {isSignUp ? 'Sign up' : 'Log in'} with Google
      </Button>
      {/* <Button
        variant="secondary"
        width="100%"
        className={cn(styles.socialButton, 'm-b-32')}
        onClick={authWithFacebook}
        size="M"
        iconLeft={<FacebookIcon />}>
        {isSignUp ? 'Sign up' : 'Log in'} with Facebook
      </Button> */}
      <Divider className="m-b-32">
        <Typography variant="bodyS">or</Typography>
      </Divider>
      <form
        onSubmit={handleSubmit(({ email }) => handleRecaptchaVerify(email))}
      >
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid } }) => (
            <InputField
              label={"Email"}
              placeholder="Enter your email"
              className="m-b-24"
              suffix={
                isSignUp ? (
                  <Tooltip
                    className={cn(styles.emailInputHelpIconTooltip)}
                    content={
                      <Typography variant="bodyXs">
                        To complete registration, you'll need to verify your email. Make sure to use an email address
                        you won't lose access to!
                      </Typography>
                    }
                    placement="top-start"
                    withArrow
                    offset={[0, 20]}
                  >
                    <HelpCircleIcon className={cn(styles.emailInputHelpIcon)} />
                  </Tooltip>
                ) : null
              }
              onChange={onChange}
              onBlur={onBlur}
              invalid={invalid}
              errorText={error?.message}
              value={value}
            />
          )}
        />
        <Button variant="primary" width="100%" type="submit" size="M" className="m-b-32" loading={isLoading}>
          Continue with Email
        </Button>
        <Typography color="gray300" variant="bodyS" align="center">
          Don’t have an account?{" "}
          <ButtonLink variant="link" size="XS" to={isSignUp ? "/login" : "/signup"}>
            {isSignUp ? "Log In" : "Sign Up"}
          </ButtonLink>
        </Typography>
      </form>
      <div ref={recaptchaRef} />
    </>
  );
};

export default LoginStep;
