import { CheckFillIcon, ChevronDownIcon, CloseCircleIcon, RightIcon } from 'assets/svg';
import { Button, Typography } from 'components';
import { useState } from 'react';
import BlockEntryOption from '../BlockEntryOption/BlockEntryOption';
import DenyEntryOption from '../DenyEntryOption/DenyEntryOption';
import styles from './MoreOptions.module.scss';

interface MoreOptionsProps {
  onDeny: () => void;
  onBlock: () => void;
  onClose: () => void;
}

const MoreOptions = ({ onDeny, onBlock, onClose }: MoreOptionsProps) => {
  const [showDeny, setShowDeny] = useState(false);
  const [showBlock, setShowBlock] = useState(false);

  const toggleDeny = () => {
    setShowDeny(!showDeny);
  };

  const toggleBlock = () => {
    setShowBlock(!showBlock);
  };

  if (showDeny) {
    return <DenyEntryOption onConfirm={onDeny} onClose={toggleDeny} />;
  }

  if (showBlock) {
    return <BlockEntryOption onConfirm={onBlock} onClose={toggleBlock} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <CheckFillIcon />
          <Typography variant="bodyXl">Valid Ticket!</Typography>
        </div>
        <CloseCircleIcon className={styles.headerCloseIcon} onClick={onClose} />
      </div>

      <div>
        <Button variant="secondary" width="100%" type="button" size="M" className="m-b-12" onClick={toggleDeny}>
          Entry Denied
        </Button>
        <Button
          variant="secondary"
          width="100%"
          type="button"
          size="M"
          className="m-b-12"
          iconRight={<RightIcon />}
          onClick={toggleBlock}>
          Entry Blocked
        </Button>
        <Button
          variant="tertiary"
          width="100%"
          type="button"
          size="M"
          className="m-b-12"
          iconRight={<ChevronDownIcon />}
          onClick={onClose}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default MoreOptions;
