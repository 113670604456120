import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ACCESS_TOKEN, REFRESH_TOKEN, ROUTES } from 'consts';
import { handleErrorNotify } from 'helpers';

export interface BaseQueryError {
  status: string | number;
  data: {
    details?: {
      [key: string]: unknown;
    };
    statusCode: number;
    message?: string;
  };
}

export const getBaseQuery: (
  query?: string,
) => BaseQueryFn<
  string | (FetchArgs & { _enableErrorHandler?: boolean }),
  unknown,
  FetchBaseQueryError | BaseQueryError
> = (query) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_PUBLIC_URL}${query || ''}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
      // Try to refresh the token
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      if (refreshToken) {
        const refreshResult: any = await baseQuery(
          // chnage
          {
            url: '/auth/account/refresh-tokens',
            method: 'POST',
            body: { refreshToken },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data && refreshResult.data.access) {
          localStorage.setItem(ACCESS_TOKEN, refreshResult.data.access);
          localStorage.setItem(REFRESH_TOKEN, refreshResult.data.refresh);

          // Retry the original query with the new token
          result = await baseQuery(args, api, extraOptions);
        } else {
          // some issue with data
          localStorage.clear();
          window.location.href = ROUTES.LOGIN;
        }
      } else {
        localStorage.clear();
        window.location.href = ROUTES.LOGIN;
      }
    } else if (result.error && typeof args === 'object' && '_enableErrorHandler' in args && args._enableErrorHandler) {
      handleErrorNotify(result.error);
    }
    return result;
  };
};
