import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery } from 'helpers';
import { IReqArgs, IStreamStatistics } from 'types';

export const streamApi = createApi({
  reducerPath: 'streamApi',
  baseQuery: getBaseQuery('/stream'),
  endpoints: (builder) => ({
    getStreamStatistics: builder.query<IStreamStatistics, IReqArgs<{ id: string }>>({
      query: ({ id, config }) => ({
        url: `/statistics/${id}`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
    getStreamAccess: builder.query<void, IReqArgs<{ eventId: string }>>({
      query: ({ eventId, config }) => ({
        url: `/access/${eventId}`,
        _enableErrorHandler: config?._enableErrorHandler,
      }),
    }),
  }),
});

export const { useLazyGetStreamStatisticsQuery, useGetStreamStatisticsQuery, useLazyGetStreamAccessQuery, useGetStreamAccessQuery } = streamApi;

export default streamApi.reducer;