import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'styles/general.scss';
import 'focus-visible'; // check in modules global style TODO
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.css';
import { ToastContainer } from 'react-toastify';
import { CloseIcon } from 'assets/svg';
import { ScrollToTop } from 'components';
import { Provider } from 'react-redux';
import { store } from 'store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
        <ToastContainer
          closeOnClick
          hideProgressBar
          position="bottom-center"
          closeButton={({ closeToast }) => <CloseIcon className="Toastify__close-icon " onClick={closeToast} />}
        />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
